import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import VenueProvider from "context/VenueContext";
import ThemeProvider from "context/ThemeContext";
import OrderProvider from "context/Order";
import ModalProvider from "context/ModalContext";
import DrawerProvider from "context/DrawerContext";
import CacheBuster from "./CacheBuster";
import Routes from "./Routes";
import OrderCompleteModal from "components/OrderCompleteModal";

function App() {
  return (
    <CacheBuster>
      <Router>
        <VenueProvider>
          <ThemeProvider>
            <OrderProvider>
              <ModalProvider>
                <DrawerProvider>
                  <Routes />

                  <OrderCompleteModal />

                  <ToastContainer
                    transition={Slide}
                    position="top-center"
                    autoClose={4000}
                    hideProgressBar
                    closeOnClick
                    pauseOnFocusLoss
                    draggableDirection="y"
                  />
                </DrawerProvider>
              </ModalProvider>
            </OrderProvider>
          </ThemeProvider>
        </VenueProvider>
      </Router>
    </CacheBuster>
  );
}

export default App;
