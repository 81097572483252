const IndexPage = () => {
  return (
    <div className="min-h-full flex items-center">
      <div className="block w-40 m-auto p-4 rounded-lg bg-purple-600 bg-gradient-to-r from-indigo-700 to-purple-600">
        <img className="block" src="/logo.svg" alt="Table Service App" />
      </div>
    </div>
  );
};

export default IndexPage;
