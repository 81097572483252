import { useCallback, useReducer } from "react";
import useLocalStorage from "./use-local-storage";

/**
 * Wrapper around useReducer to use local storage
 *
 * @param {function} reducer React reducer function
 * @param {object} initialState React reducer initialState
 * @param {string} key localStorage key for order against venue
 * @param {number} ttl How long the localStorage is stored for in ms
 *
 * @returns useReducer React Hook
 */
export default function usePersistReducer(reducer, initialState, key, ttl) {
  const [state, setState] = useLocalStorage(key, initialState, ttl);

  // Wrap reducer with a memoized function
  // that syncs newState to localStorage before returning newState
  const reducerLocalStorage = useCallback(
    (state, action) => {
      const newState = reducer(state, action);

      setState(newState);

      return newState;
    },
    [setState]
  );

  return useReducer(reducerLocalStorage, state);
}
