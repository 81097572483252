import { useModal } from "context/ModalContext";
import { useVenue } from "context/VenueContext";
import { useTheme } from "context/ThemeContext";
import { useOrder } from "context/Order";
import Modal from "components/Modal";

const OrderCompleteModal = () => {
  const venue = useVenue();
  const theme = useTheme();

  if (!venue) return null;

  const { complete, postCompleteOrder } = useOrder();
  const { closeModal } = useModal();

  const handleClose = () => {
    postCompleteOrder();
    closeModal();
  };

  const isOpen = complete !== null;

  return (
    <Modal open={complete} onClose={handleClose}>
      {isOpen && (
        <div className="p-4 text-center">
          <h2 className="mb-4 text-xl font-bold">Thank you for your order.</h2>

          <p className="mb-2 text-lg leading-tight">Your order reference is:</p>

          <div className="mb-2 text-3xl font-bold">{complete}</div>

          <p className="mb-6 text-lg leading-tight">
            Enjoy your order at {venue.name}.
          </p>

          <button
            type="button"
            onClick={handleClose}
            className="block w-full max-w-sm mx-auto p-3 rounded-lg font-bold text-base uppercase text-white bg-purple-600 focus:outline-none"
            style={{ color: theme.accent, backgroundColor: theme.primary }}
          >
            Return to Menu
          </button>
        </div>
      )}
    </Modal>
  );
};

export default OrderCompleteModal;
