import clsx from "clsx";
import { useTheme } from "context/ThemeContext";
import { formatCurrency } from "lib/helper";

const ProductExtras = (props) => {
  const theme = useTheme();

  return (
    <div className="border-t-3 border-gray-100">
      <div className="p-3">
        <h3 className="mb-2 font-bold text-xl leading-tight">Select Options</h3>

        <ul className="mb-2 space-y-2">
          {props.extras.map((extra, index) => {
            const isChecked = !!props.selected.find(
              (s) => s.name === extra.name && s.price === extra.price
            );

            return (
              <li key={`product-${props.id}-extra-${index}`}>
                <input
                  id={`extra-${index}`}
                  type="checkbox"
                  value={index}
                  checked={isChecked}
                  onChange={() => props.onChange(extra, isChecked)}
                  className="sr-only"
                />

                <label
                  htmlFor={`extra-${index}`}
                  className="relative flex flex-wrap w-full py-1 pr-7 text-left font-bold text-lg leading-tight focus:outline-none"
                >
                  <h4 className="mr-1">{extra.name}</h4>
                  {extra.price > 0 && (
                    <span className="inline-block whitespace-nowrap">
                      (+{formatCurrency(extra.price)})
                    </span>
                  )}

                  <div
                    className={clsx(
                      "absolute inset-y-0 my-auto w-6 h-6 right-0 transition rounded border-3 border-purple-600",
                      isChecked ? "text-white bg-purple-600" : "bg-transparent"
                    )}
                    style={{
                      color: theme.accent,
                      borderColor: theme.primary,
                      backgroundColor: isChecked ? theme.primary : "inherit",
                    }}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      className={clsx(
                        "transition-opacity",
                        isChecked ? "opacity-100" : "opacity-0"
                      )}
                      fill="currentColor"
                    >
                      <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                    </svg>
                  </div>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ProductExtras;
