import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { isEmpty, toLower, get, round } from "lodash";
import Bugsnag from "@bugsnag/js";
import { useVenue } from "context/VenueContext";
import { useOrder } from "context/Order";
import { placeOrder, queuePrintJobs } from "lib/place-order";
import { decrementProductStock } from "lib/product-stock";
import { formatCurrency } from "lib/helper";

export default function useCheckout() {
  const history = useHistory();

  const venue = useVenue();
  const { order, completeOrder } = useOrder();

  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );

  const [state, setState] = useState({
    step: clientSecret ? "payment" : null,
    error: null,
    addressError: null,
    nameError: null,
    phoneError: null,
    tableError: null,
    loading: false,
  });

  // Table changes, reset error state
  useEffect(() => {
    setState({ ...state, tableError: null });
  }, [venue.tables, order.table]);

  // Address changes, reset error state
  useEffect(() => {
    setState({ ...state, addressError: null });
  }, [order?.address]);

  function handleConfirmation() {
    if (venue?.takeaway && !metMinOrder(venue, order)) {
      let minOrderAmount = formatCurrency(venue.delivery.minOrder);

      setState({
        ...state,
        addressError: null,
        nameError: null,
        phoneError: null,
        tableError: null,
        error: `Minimum order amount of ${minOrderAmount} not met.`,
      });
      return;
    }

    if (venue?.takeaway) {
      if (order.takeaway === "collection") {
        if (order?.customerName) {
          setState({
            ...state,
            step: "confirmation",
            error: null,
            addressError: null,
            nameError: null,
            phoneError: null,
            tableError: null,
          });
        } else {
          setState({
            ...state,
            error: null,
            addressError: null,
            phoneError: null,
            tableError: null,
            nameError: !order?.customerName ? "Please enter your name." : null,
          });
        }
      } else if (order.takeaway === "delivery") {
        if (order?.address && order?.telephone) {
          setState({
            ...state,
            step: "confirmation",
            error: null,
            addressError: null,
            nameError: null,
            phoneError: null,
            tableError: null,
          });
        } else {
          setState({
            ...state,
            error: null,
            nameError: null,
            tableError: null,
            addressError: !order?.address ? "Please enter your address." : null,
            phoneError: !order?.telephone
              ? "Please enter your phone number."
              : null,
          });
        }
      }
    } else {
      const table = confirmTableAvailability();

      if (table) {
        setState({ ...state, step: "confirmation" });
      }
    }
  }

  async function handleConfirmationSubmit() {
    if (hasPaymentGateways(venue)) {
      setState({ ...state, step: "payment" });
    } else {
      await handlePlaceOrder(null);
    }
  }

  async function handlePaymentSubmit(payment = null) {
    await handlePlaceOrder(payment);
  }

  // Cancel any step is checkout
  function handleCancel() {
    setState({ ...state, step: null });
  }

  // Process an order
  async function handlePlaceOrder(payment) {
    if (state.loading) return;

    try {
      setState({ ...state, loading: true });

      // Place Order
      const { id, ref } = await placeOrder(venue, order, payment);

      // Queue Print Job
      await queuePrintJobs(venue, ref);

      // Manage Stock Decrements
      await decrementProductStock(venue, order.items);

      completeOrder(id);

      history.push(`/venues/${venue.id}`, { back: true });
    } catch (error) {
      console.error(error);
      Bugsnag.notify(error);
    }
  }

  function confirmTableAvailability() {
    const table = checkTableNumber(venue.tables, order.table);

    if (table) return table;

    setState({
      ...state,
      tableError: isEmpty(order.table)
        ? venue?.hotel
          ? "Enter your room number"
          : "Enter your table number"
        : venue?.hotel
        ? "Room not found"
        : "Table not available",
    });

    return;
  }

  const actions = {
    handleConfirmation,
    handleConfirmationSubmit,
    handlePaymentSubmit,
    handleCancel,
  };

  return [state, actions];
}

/**
 * Checks the table number against all venue tables
 */
function checkTableNumber(tables, number) {
  if (isEmpty(number)) return;

  // Format user input number to lowercase string
  const customerTableNumber = toLower(number);

  return tables.find((table) => {
    // Format user input number to lowercase string
    const tableNumber = toLower(table.number);

    return tableNumber === customerTableNumber;
  });
}

/**
 * Get array of payment methods available
 *
 * @param {object} venue Venue
 */
function hasPaymentGateways(venue) {
  let methods = ["stripe", "stripeConnect", "paymentsense"];

  return methods.filter((key) => get(venue, `payments.${key}.enabled`, false));
}

function metMinOrder(venue, order) {
  let isDelivery = order.takeaway === "delivery";
  let minOrder = round(venue?.delivery?.minOrder || 0, 2);

  if (isDelivery && order.subtotal < minOrder) return false;

  return true;
}
