import { red } from "tailwindcss/colors";

export default {
  iconStyle: "solid",
  style: {
    base: {
      color: "#000",
      fontFamily: "Gilroy, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      ":-webkit-autofill": {
        color: "#000",
      },
      "::placeholder": {
        color: "#000",
      },
    },
    invalid: {
      color: red[500],
      iconColor: red[500],
    },
  },
};
