import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import toNumber from "lodash/toNumber";
import inRange from "lodash/inRange";
import { useOrder } from "context/Order";
import { useTheme } from "context/ThemeContext";

const QuantitySelector = (props) => {
  const theme = useTheme();

  const [value, setValue] = useState(props.quantity);

  useEffect(() => {
    setValue(props.quantity);
  }, [props.quantity]);

  const { updateItem, removeItem } = useOrder();

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);

    const quantity = toNumber(value);
    const stockAvailable = inRange(quantity, 1, props.max.remaining + 1);

    if (stockAvailable) {
      updateItem(props.item, "quantity", quantity);
    } else if (quantity > props.max.remaining) {
      updateItem(props.item, "quantity", props.max.remaining);

      toast(
        `There are only ${props.max.productStock} ${props.item.name} available.`,
        {
          toastId: `quantity-${props.item.id}`,
        }
      );
    }
  };

  const handleBlur = () => {
    if (value === "0") removeItem(props.item);

    const quantity = toNumber(value);
    const stockAvailable = inRange(quantity, 1, props.max.remaining + 1);

    if (!stockAvailable) setValue(props.quantity);
  };

  return (
    <input
      type="number"
      pattern="[0-9]*"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      className="w-12 py-2 border-3 border-purple-600 rounded-lg font-bold text-center leading-none appearance-none no-spin-button focus:outline-none"
      style={{ borderColor: theme.primary }}
    />
  );
};

export default QuantitySelector;
