import get from "lodash/get";
import round from "lodash/round";

/**
 * Format Price Currency
 *
 * @param {string} url URL String
 * @returns {string} Formatted URL string
 */
export const formatCurrency = (string) => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(string);
};

/**
 * Format Address Values
 *
 * @param {object} venue Venue Object
 * @returns {string} Comma separated address string
 */
export const formatAddress = (venue) => {
  const address = [
    venue.address1,
    venue.address2,
    venue.town,
    venue.city,
    venue.postcode,
  ].filter((v) => v);

  return address.join(", ");
};

/**
 * Pluralize a string depending on count
 *
 * @param {integer} count Integer to pluralize
 * @param {string} noun Singular string
 * @param {string} suffix Convert singular string to plural
 */
export const pluralize = (count, noun, suffix = "s") => {
  return `${count} ${noun}${count !== 1 ? suffix : ""}`;
};

/**
 * Calculate an orders total price
 *
 * @param {object} order Order object
 */
export const calculateTotalOrderPrice = (order) => {
  // Order total is accumulative total of all items
  const total = order.items.reduce((acc, curr) => acc + curr.total, 0);
  return round(total, 2);
};

/**
 * Calculate an order line item price
 *
 * @param {object} item Order line item
 */
export const calculateTotalItemPrice = (item) => {
  // Calculate total of all extras so far
  let extras = item.extras.reduce((acc, curr) => acc + curr.price, 0);
  extras = round(extras, 2);

  // Total price = (price + extras) * quantity
  return round((item.price + extras) * item.quantity, 2);
};

/**
 * Get array of payment methods available
 *
 * @param {object} venue Venue
 */
export const getPaymentMethods = (venue, excludeCash = false) => {
  let methods = ["stripe", "stripeConnect", "paymentsense"];

  // Whether to include cash
  if (!excludeCash) methods.push("cash");

  return methods.filter((key) => get(venue, `payments.${key}.enabled`, false));
};
