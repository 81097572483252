import useCheckout from "./useCheckout";
import Header from "./Header";
import VenueInfo from "./VenueInfo";
import BasketList from "./BasketList";
import DeliveryCost from "./DeliveryCost";
import OrderAddress from "./OrderAddress";
import OrderTelephone from "./OrderTelephone";
import CustomerName from "./CustomerName";
import OrderNotes from "./OrderNotes";
import Tips from "./Tips";
import TableSelector from "./TableSelector";
import OrderConfirmModal from "./OrderConfirmModal";
import OrderPaymentModal from "./OrderPaymentModal";
import BasketButton from "components/BasketButton";
import { useVenue } from "context/VenueContext";

export default function Checkout() {
  const venue = useVenue();
  const [state, actions] = useCheckout();

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="flex-initial py-2 px-4">
          <Header />

          {state.error && (
            <div className="bg-red-500 text-white rounded py-1 px-2 mt-2">
              {state.error}
            </div>
          )}
        </div>

        <div className="flex-1 py-2 px-4 overflow-y-auto">
          <VenueInfo />

          <BasketList />

          {venue?.takeaway && <DeliveryCost />}

          {venue?.takeaway && <OrderAddress error={state.addressError} />}

          {venue?.takeaway && <OrderTelephone error={state.phoneError} />}

          {venue?.takeaway && <CustomerName error={state.nameError} />}

          <OrderNotes />
        </div>

        <Tips />

        {!venue?.takeaway && <TableSelector error={state.tableError} />}

        <BasketButton
          text="Confirm Order"
          onClick={actions.handleConfirmation}
        />
      </div>

      {state.step === "confirmation" && (
        <OrderConfirmModal
          loading={state.loading}
          onSubmit={actions.handleConfirmationSubmit}
          onClose={actions.handleCancel}
        />
      )}

      {state.step === "payment" && (
        <OrderPaymentModal
          loading={state.loading}
          onSubmit={actions.handlePaymentSubmit}
          onClose={actions.handleCancel}
        />
      )}
    </>
  );
}
