import clsx from "clsx";
import round from "lodash/round";
import { useOrder } from "context/Order";
import { formatCurrency } from "lib/helper";
import { useTheme } from "context/ThemeContext";

const Options = (props) => {
  const { order, setTip } = useOrder();

  const tenPercent = round(order.subtotal * 0.1, 2);
  const fifteenPercent = round(order.subtotal * 0.15, 2);

  return (
    <ul className="flex space-x-2">
      <li className="flex-1">
        <Button
          label="Not Now"
          value={0}
          isActive={!order.tip}
          onClick={setTip}
        />
      </li>
      <li className="flex-1">
        <Button
          label="10%"
          value={tenPercent}
          isActive={order.tip > 0 && order.tip === tenPercent}
          onClick={setTip}
        />
      </li>
      <li className="flex-1">
        <Button
          label="15%"
          value={fifteenPercent}
          isActive={order.tip > 0 && order.tip === fifteenPercent}
          onClick={setTip}
        />
      </li>
      <li className="flex-1">
        <Button
          label="Custom"
          onClick={props.onCustom}
          isActive={
            order.tip > 0 &&
            order.tip !== tenPercent &&
            order.tip !== fifteenPercent
          }
        />
      </li>
    </ul>
  );
};

const Button = (props) => {
  const theme = useTheme();

  return (
    <button
      type="button"
      onClick={() => props.onClick(props.value)}
      className={clsx(
        "flex w-full h-12 p-1 items-center justify-center text-base leading-5 bg-gray-100 focus:outline-none",
        props.isActive && "text-white bg-purple-600"
      )}
      style={
        props.isActive
          ? { color: theme.accent, backgroundColor: theme.primary }
          : null
      }
    >
      <div>
        <div className="font-bold">{props.label}</div>
        {props.value > 0 && (
          <div className="opacity-70">{formatCurrency(props.value)}</div>
        )}
      </div>
    </button>
  );
};

export default Options;
