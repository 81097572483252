import { Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useVenue } from "context/VenueContext";

const VenueRoute = (props) => {
  const venue = useVenue();

  if (!venue) return <Redirect to="/" />;

  return (
    <>
      <Helmet>
        <title>{venue.name}</title>
      </Helmet>

      <Route {...props} />
    </>
  );
};

export default VenueRoute;
