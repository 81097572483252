import { Link } from "react-router-dom";

const BackLink = (props) => (
  <Link
    to={{ pathname: props.to, state: { back: true } }}
    className={props.className}
  >
    {props.screenReaderText && (
      <span className="sr-only">{props.screenReaderText}</span>
    )}

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className="w-5">
      <path
        fill="none"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 1L5 11l10 10"
      />
    </svg>
  </Link>
);

export default BackLink;
