import clsx from "clsx";
import { useOrder } from "context/Order";
import { useTheme } from "context/ThemeContext";
import { useVenue } from "context/VenueContext";

const TableSelector = (props) => {
  const venue = useVenue();
  const theme = useTheme();
  const { order, setTableNumber } = useOrder();

  const handleChange = (e) => setTableNumber(e.target.value);

  const inputFontSize = () => {
    if (order.table < 100) return "text-4xl";
    if (order.table < 1000) return "text-3xl";
    return "text-xl";
  };

  return (
    <div className="flex items-center justify-center space-x-4 py-3 px-4 border-t-3 border-gray-100">
      <label htmlFor="table">
        <h3 className="text-3xl font-bold">
          {venue?.hotel ? "Room Number" : "Table Number"}
        </h3>
        {props.error && (
          <span className="text-sm text-red-500">{props.error}</span>
        )}
      </label>

      <input
        id="table"
        type="text"
        value={order.table}
        onChange={handleChange}
        className={clsx(
          "w-16 h-16 py-1.5 text-center border-3 transition rounded-lg font-bold leading-none appearance-none no-spin-button focus:outline-none",
          inputFontSize(),
          props.error ? "border-red-500" : "border-purple-600"
        )}
        style={!props.error ? { borderColor: theme.primary } : null}
      />
    </div>
  );
};

export default TableSelector;
