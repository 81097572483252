import { useHistory, useLocation } from "react-router";
import { motion } from "framer-motion";

const transition = { ease: "easeInOut", duration: 0.3 };

const Page = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  const pageVariants = {
    initial: () => {
      // User navigated back in browser, don't duplicate animation
      if (history.action === "POP") return {};

      return {
        x: location.state?.back ? "-25%" : "100%",
        boxShadow: "0 20px 40px -10px rgba(0, 0, 0, 0.25)",
      };
    },
    enter: {
      x: 0,
      position: "fixed",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      transitionEnd: {
        position: "relative",
      },
    },
    exit: (state) => {
      // User navigated back in browser, don't duplicate animation
      if (history.action === "POP") return {};

      if (state?.back) {
        return {
          x: "100%",
          zIndex: 1,
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        };
      }

      return {
        x: "-25%",
        zIndex: -1,
      };
    },
  };

  return (
    <motion.div
      className="bg-white h-full overflow-y-auto select-none"
      initial="initial"
      animate="enter"
      exit="exit"
      variants={pageVariants}
      transition={transition}
    >
      {children}
    </motion.div>
  );
};

export default Page;
