import React, { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import get from "lodash/get";
import toNumber from "lodash/toNumber";
import { useVenue } from "context/VenueContext";
import reducer, { initialState } from "./reducer";
import usePersistReducer from "lib/use-persist-reducer";
import { getProductMaxStock } from "lib/product-stock";

const OrderContext = React.createContext();

export default function Provider({ children }) {
  const venue = useVenue();

  if (!venue) return children;

  const [state, dispatch] = usePersistReducer(
    reducer,
    initialState,
    `order-${venue.id}`,
    2 * 60 * 60 * 1000
  );

  const total = get(state, "order.total", 0);
  const subtotal = get(state, "order.subtotal", 0);

  // Clear order if the venue isn't open
  useEffect(() => {
    if (!venue.isOpen && total > 0) {
      dispatch({ type: "CLEAR_ORDER" });
    }
  }, [venue.isOpen, total]);

  // Set takeaway if venue is
  useEffect(() => {
    let canDeliver = venue?.delivery?.enabled;
    let canCollect = venue?.collection?.enabled;

    let takeaway = canDeliver ? "delivery" : canCollect ? "collection" : null;
    if (takeaway) dispatch({ type: "SET_TAKEAWAY_TYPE", takeaway });
  }, [venue?.takeaway]);

  // Listen for product changes
  useEffect(() => {
    if (total > 0) {
      let items = [...state.order.items];
      items = checkProductAvailability(venue.products, items);
      items = checkStockLevels(venue.products, items);

      dispatch({ type: "SET_ITEMS", items });
    }
  }, [venue.products, total]);

  // Set delivery cost based upon order
  useEffect(() => {
    // Collection
    if (state.order.takeaway === "collection") {
      dispatch({ type: "SET_DELIVERY", cost: 0 });
      dispatch({ type: "SET_ADDRESS", address: null });
      dispatch({ type: "SET_TELEPHONE", telephone: "" });
    }
    // Delivery
    else if (state.order.takeaway === "delivery") {
      let deliveryCost = toNumber(venue?.delivery?.cost || 0);
      let threshold = toNumber(venue?.delivery?.freeThreshold || 0);

      dispatch({ type: "SET_CUSTOMER_NAME", name: "" });

      if (subtotal >= threshold) {
        dispatch({ type: "SET_DELIVERY", cost: 0 });
      } else {
        dispatch({ type: "SET_DELIVERY", cost: deliveryCost });
      }
    }
  }, [state.order.takeaway, subtotal]);

  const actions = {
    addItem: (item) => dispatch({ type: "ADD_ITEM", item }),
    removeItem: (item) => dispatch({ type: "REMOVE_ITEM", item }),
    updateItem: (item, key, value) => {
      return dispatch({ type: "UPDATE_ITEM", item, key, value });
    },
    setTip: (tip) => dispatch({ type: "SET_TIP", tip }),
    setTableNumber: (table) => dispatch({ type: "SET_TABLE_NUMBER", table }),
    setNotes: (notes) => dispatch({ type: "SET_NOTES", notes }),
    setPastOrder: () => dispatch({ type: "SET_PAST_ORDER" }),
    completeOrder: (orderID) => dispatch({ type: "COMPLETE_ORDER", orderID }),
    postCompleteOrder: () => dispatch({ type: "POST_COMPLETE_ORDER" }),
    clearOrder: () => dispatch({ type: "CLEAR_ORDER" }),
    setAddress: (address) => dispatch({ type: "SET_ADDRESS", address }),
    setTelephone: (telephone) => dispatch({ type: "SET_TELEPHONE", telephone }),
    setCustomerName: (name) => dispatch({ type: "SET_CUSTOMER_NAME", name }),
    setTakeawayType: (takeaway) =>
      dispatch({ type: "SET_TAKEAWAY_TYPE", takeaway }),
  };

  return (
    <OrderContext.Provider value={{ ...state, ...actions }}>
      {children}
    </OrderContext.Provider>
  );
}

/**
 * React hook to access order context
 *
 * @returns {object}  context  The order context values
 */
export function useOrder() {
  const context = useContext(OrderContext);

  return context;
}

/**
 * Check Product Availability of items in the current order and update
 *
 * @param {array} products Venue Products
 * @param {array} items Order Items
 *
 * @returns {array} Items to remove from the order
 */
function checkProductAvailability(products, items) {
  return items.filter((item) => {
    // Find corresponding product from venue products
    const product = products.find((p) => p.id === item.id);

    // Product not found, assume it's not available
    if (!product) {
      toast(
        `${item.name} is no longer available. Your basket has been updated.`,
        { toastId: `unavailable-${item.id}` }
      );
    }

    return !!product;
  });
}

/**
 * Check Stock Levels of items in the current order and update
 *
 * @param {array} products Venue Products
 * @param {array} items Order Items
 *
 * @returns {array} Updated `items` Array
 */
function checkStockLevels(products, items) {
  // Keep track of basket stock as we loop items array
  let basket = {};

  return items.reduce((acc, item) => {
    // Find corresponding product from venue products
    const product = products.find((p) => p.id === item.id);

    if (!product) return acc;

    // Get Basket Stock
    const basketStock = get(basket, product.id, 0);

    // Product Stock
    const { productStock } = getProductMaxStock(product);

    // Keep track of basket stock
    basket[product.id] = basketStock + item.quantity;

    // Calculate remaining stock
    const remaining = productStock - basketStock;

    // Plenty of stock, leave item as-is
    if (remaining >= item.quantity) return [...acc, item];

    // No stock left for product, remove it
    if (remaining < 1) {
      toast(`${item.name} is out of stock. Your basket has been updated.`, {
        toastId: `quantity-${item.id}`,
      });

      return acc;
    }

    // Adjust order item quantity to reflect stock left
    toast(
      `${item.name} only has ${productStock} remaining. Your basket has been updated.`,
      { toastId: `quantity-${item.id}` }
    );

    return [...acc, { ...item, quantity: remaining }];
  }, []);
}
