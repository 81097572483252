import { useState, useEffect } from "react";
import clsx from "clsx";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { isEmpty, pick } from "lodash";
import Bugsnag from "@bugsnag/js";
import { usePayment } from "context/PaymentContext";
import { useTheme } from "context/ThemeContext";
import { useVenue } from "context/VenueContext";
import { useOrder } from "context/Order";
import { functions } from "lib/firebase";
import AppleGooglePay from "./AppleGooglePay";
import { ReactComponent as Loader } from "./loader.svg";
import CARD_STYLES from "./styles";

const stripePaymentIntent = functions.httpsCallable("stripePaymentIntent");

export default function StripeCheckout() {
  let isMounted = true;

  const theme = useTheme();

  const stripe = useStripe();
  const elements = useElements();
  const payment = usePayment();
  const venue = useVenue();
  const { order } = useOrder();

  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    stripePaymentIntent({
      venue: venue.id,
      amount: order.total,
    })
      .then((res) => {
        if (isMounted) {
          setClientSecret(res.data.clientSecret);
        }
      })
      .catch((error) => {
        console.error(error);
        Bugsnag.notify(error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const isLoading = (value) => {
    setLoading(value);
    payment.setLoading(value);
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (payment.loading) return;

    try {
      isLoading(true);

      if (isEmpty(clientSecret)) {
        throw new Error(
          "We were unable to process your payment. Please try again."
        );
      }

      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (payload.error) {
        throw new Error(`Payment failed. ${payload.error.message}`);
      }

      handleSubmitSuccess(payload.paymentIntent);
    } catch (error) {
      isLoading(false);
      setError(error.message);
    }
  };

  const handleSubmitSuccess = (paymentIntent) => {
    payment.handleSubmit({
      provider: "stripe",
      ...pick(paymentIntent, ["id", "status", "amount", "payment_method"]),
    });
  };

  return (
    <div>
      <form id="payment-form" onSubmit={handleSubmit} className="relative">
        <CardElement
          id="card-element"
          options={CARD_STYLES}
          onChange={handleChange}
          className="block border-2 border-b-0 rounded-t-lg p-3 border-gray-200"
        />

        <button
          id="submit"
          className={clsx(
            "block w-full py-3 px-4 leading-snug rounded-b-lg font-bold uppercase select-none text-black transition bg-purple-600 focus:outline-none disabled:bg-gray-200",
            loading && "cursor-wait",
            payment.loading && "cursor-not-allowed"
          )}
          style={
            payment.loading || loading || disabled
              ? null
              : { color: theme.accent, backgroundColor: theme.primary }
          }
          disabled={payment.loading || loading || disabled}
        >
          {loading ? (
            <div className="inline-flex align-top flex-nowrap space-x-2 items-center">
              <Loader className="animate-spin h-5 w-5" />
              <span>Placing Order</span>
            </div>
          ) : (
            <span>Pay now</span>
          )}
        </button>

        {error && (
          <div className="mt-2 font-bold text-red-500" role="alert">
            {error}
          </div>
        )}
      </form>

      {clientSecret && (
        <AppleGooglePay
          clientSecret={clientSecret}
          disabled={loading}
          onSubmit={handleSubmitSuccess}
        />
      )}
    </div>
  );
}
