import { AnimatePresence, motion } from "framer-motion";
import { useTheme } from "context/ThemeContext";
import QuantitySelector from "./QuantitySelector";

const AddToBasket = (props) => {
  const theme = useTheme();

  const noStock = props.max.remaining < 1;

  return (
    <div className="flex-initial border-t-3 border-gray-100">
      <div className="p-3 pb-0 text-center">
        <AnimatePresence initial={false}>
          <motion.div
            key={noStock ? "not-available" : "available"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {noStock ? (
              <p className="font-bold text-red-500">
                Due to stock limitations, you cannot add anymore of this item to
                your order.
              </p>
            ) : (
              <>
                <QuantitySelector {...props} />

                <button
                  type="button"
                  onClick={props.onSubmit}
                  className="block w-full p-3 rounded-lg font-bold text-base uppercase text-white bg-purple-600 focus:outline-none"
                  style={{
                    color: theme.accent,
                    backgroundColor: theme.primary,
                  }}
                >
                  Add to Order
                </button>
              </>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default AddToBasket;
