import { v4 as uuidv4 } from "uuid";
import { formatCurrency } from "lib/helper";

const BasketListItemExtras = ({ extras }) => (
  <ul className="text-sm">
    {extras.map((extra) => (
      <li key={uuidv4()}>
        + {extra.name}
        {extra.price > 0 && (
          <span className="ml-1">(+{formatCurrency(extra.price)})</span>
        )}
      </li>
    ))}
  </ul>
);

export default BasketListItemExtras;
