import React, { useState, useContext, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useVenue } from "./VenueContext";

const ModalContext = React.createContext();

const Provider = ({ children }) => {
  const venue = useVenue();

  if (!venue) return children;

  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);

  const closeModal = () => setOpen(false);

  // Clear order if the venue isn't open
  useEffect(() => {
    if (!venue.isOpen) setOpen(false);
  }, [venue.isOpen]);

  return (
    <ModalContext.Provider value={{ open, openModal, closeModal }}>
      {children}

      <AnimatePresence>
        {open && (
          <motion.div
            className="fixed z-20 inset-0 w-full h-full bg-black"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            onClick={closeModal}
          />
        )}
      </AnimatePresence>
    </ModalContext.Provider>
  );
};

export const Consumer = ModalContext.Consumer;

export const useModal = () => useContext(ModalContext);

export default Provider;
