import get from "lodash/get";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { version as APP_VERSION } from "../package.json";
import { db } from "lib/firebase";

export default function CacheBuster(props) {
  const [app, loading] = useDocumentData(db.collection("global").doc("app"));

  if (loading) return null;

  const currentVersion = APP_VERSION || "1.0.0";
  const appVersion = get(app, "version", currentVersion);

  const isOutdated = semverGreaterThan(appVersion, currentVersion);

  if (isOutdated) window.location.reload();

  return props.children;
}

/**
 * Compare two semantic version string to check if A is greater than B
 *
 * @param {string} versionA  Sementic version string A
 * @param {string} versionB  Sementic version string B
 * @returns
 */
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());

    if (a === b) continue;

    return a > b || isNaN(b);
  }

  return false;
};
