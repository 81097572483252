/**
 * Reduce product stock based upon ordered items
 *
 * @param {object} venue Venue
 * @param {array} items Order items
 */
export const decrementProductStock = async (venue, items) => {
  for (const item of items) {
    // Get Product Ref
    const ref = venue.ref.collection("products").doc(item.id);

    // Get product Doc
    const doc = await ref.get();

    if (doc.exists) {
      const product = doc.data();

      // If managing stock, decrement
      if (product.manage_stock) {
        let quantity = product.quantity - item.quantity;
        quantity = quantity < 0 ? 0 : quantity;

        await ref.update({ quantity });
      }
    }
  }
};

/**
 * Get the available max stock for a product
 *
 * @param {object} product Product
 * @param {object} order Order
 */
export const getProductMaxStock = (product, order = null) => {
  // Check if the product is already in the basket, if so get quantity counts
  const basketCount =
    product && order ? getOrderQuantityByProduct(product.id, order) : 0;

  // Don't manage stock
  if (!product || !product.manage_stock) {
    return {
      productStock: 1000,
      remaining: 1000,
      basketCount,
    };
  }

  const productStock = product.quantity;

  let remaining = productStock - basketCount;
  remaining = remaining < 0 ? 0 : remaining;

  return { productStock, basketCount, remaining };
};

/**
 * Get the total count of a product in the current order
 *
 * @param {string} id Product ID
 * @param {object} order Order
 */
const getOrderQuantityByProduct = (id, order) => {
  return order.items.reduce(
    (total, item) => (item.id === id ? total + item.quantity : total),
    0
  );
};
