import { useEffect, useState } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import round from "lodash/round";
import { useVenue } from "context/VenueContext";
import { useOrder } from "context/Order";

const AppleGooglePay = (props) => {
  const stripe = useStripe();
  const venue = useVenue();
  const { order } = useOrder();

  const [error, setError] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (!stripe) return;

    const pr = stripe.paymentRequest({
      country: "GB",
      currency: "gbp",
      total: {
        label: venue.name,
        amount: round(order.total * 100),
      },
    });

    pr.on("paymentmethod", async (event) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        props.clientSecret,
        { payment_method: event.paymentMethod.id },
        { handleActions: false }
      );

      if (error) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        event.complete("fail");

        setError(`Payment failed. ${error.message}`);
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        event.complete("success");

        // Check if the PaymentIntent requires any actions and if so let Stripe.js handle the flow.
        if (paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const confirm = await stripe.confirmCardPayment(props.clientSecret);

          if (confirm.error) {
            // The payment failed -- ask your customer for a new payment method.
            setError(`Payment failed. ${confirm.error.message}`);
          } else {
            // The payment has succeeded.
            props.onSubmit(confirm.paymentIntent);
          }
        } else {
          // The payment has succeeded.
          props.onSubmit(paymentIntent);
        }
      }
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      if (result) setPaymentRequest(pr);
    });
  }, [stripe]);

  return (
    <div className="relative mt-4">
      {props.disabled && (
        <div className="absolute z-10 inset-0 bg-white opacity-50" />
      )}

      {paymentRequest && (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      )}

      {error && (
        <div className="mt-2 font-bold text-red-500" role="alert">
          {error}
        </div>
      )}
    </div>
  );
};

export default AppleGooglePay;
