import { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import pick from "lodash/pick";
import { usePayment } from "context/PaymentContext";

export default function usePaymentStatus() {
  const stripe = useStripe();

  const payment = usePayment();

  const [error, setError] = useState(null);

  useEffect(() => {
    if (!stripe) return;

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) return;

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
        case "processing":
          payment.handleSubmit({
            provider: "stripe",
            ...pick(paymentIntent, [
              "id",
              "status",
              "amount",
              "payment_method",
            ]),
          });

          break;

        default:
          setError("Payment failed. Please try another payment method.");
          break;
      }
    });
  }, [stripe]);

  return error;
}
