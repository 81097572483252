import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { functions } from "lib/firebase";
import { useOrder } from "context/Order";
import { useVenue } from "context/VenueContext";

const paymentsenseToken = functions.httpsCallable("paymentsenseToken");

const initialState = {
  token: null,
  orderID: uuidv4(),
  loading: true,
  error: null,
};

const usePaymentsense = () => {
  let isMounted = true;

  const venue = useVenue();
  const { order } = useOrder();

  const [state, setState] = useState(initialState);

  const [loadingScript, setLoadingScript] = useState(true);

  // Load external JavaScript file
  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_PAYMENTSENSE_JS;
    script.async = true;
    script.onload = () => setLoadingScript(false);

    document.body.appendChild(script);

    return () => {
      isMounted = false;
    };
  }, []);

  // Fetch Access token
  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const { data } = await paymentsenseToken({
          venue: venue.id,
          amount: order.total,
          orderID: state.orderID,
        });

        if (isMounted) {
          setState({ ...state, loading: false, token: data.id });
        }
      } catch (error) {
        if (isMounted) {
          setState({ ...state, loading: false, error: error.message });
        }
      }
    };

    if (state.loading) fetchAccessToken();
  }, [state.loading]);

  const actions = {
    handleError: (error) => setState({ ...state, error }),
    handleRetry: () => setState({ ...initialState, orderID: uuidv4() }),
  };

  const loading = loadingScript || state.loading;

  return [state.token, state.orderID, loading, state.error, actions];
};

export default usePaymentsense;
