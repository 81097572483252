import get from "lodash/get";
import { formatAddress } from "lib/helper";
import { useVenue } from "context/VenueContext";

const Header = () => {
  const venue = useVenue();

  const showAddress = get(venue, "show_address", true);

  return (
    <header className="mb-4 w-72 max-w-full mx-auto text-center">
      {venue.logo ? (
        <img
          src={venue.logo}
          alt={venue.name}
          className="block mb-2 w-full max-w-xs"
        />
      ) : (
        <h1 className="text-xl font-bold">{venue.name}</h1>
      )}

      {showAddress && <p className="text-sm">{formatAddress(venue)}</p>}
    </header>
  );
};

export default Header;
