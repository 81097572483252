import { useState } from "react";
import clsx from "clsx";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import pick from "lodash/pick";
import { usePayment } from "context/PaymentContext";
import { useTheme } from "context/ThemeContext";
import { ReactComponent as Loader } from "./loader.svg";
import usePaymentStatus from "../usePaymentStatus";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const statusError = usePaymentStatus();

  const theme = useTheme();
  const payment = usePayment();

  const [state, setState] = useState({
    loading: false,
    error: null,
  });

  let disabled = !stripe || !elements;

  let paymentError = statusError || state.error;

  const isLoading = (value) => {
    setState({ ...state, loading: true });
    payment.setLoading(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (disabled || payment.loading) return null;

    isLoading(true);

    const { paymentIntent, error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: `${window.location.origin}/checkout`,
      },
    });

    if (error) {
      console.error(error);

      isLoading(false);
      setState({ ...state, error: error.message });
    } else {
      payment.handleSubmit({
        provider: "stripe",
        ...pick(paymentIntent, ["id", "status", "amount", "payment_method"]),
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <PaymentElement />
      </div>

      {state.loading ? (
        <div className="inline-flex flex-nowrap space-x-2 items-center py-2 px-4 rounded-xl font-bold uppercase text-center text-base appearance-none border-3 border-transparent">
          <Loader className="animate-spin h-5 w-5" />
          <span>Placing Order</span>
        </div>
      ) : (
        <button
          id="submit"
          type="submit"
          className={clsx(
            "block w-full py-3 px-4 leading-snug rounded-lg font-bold uppercase select-none text-black transition bg-purple-600 focus:outline-none disabled:bg-gray-200",
            state.loading && "cursor-wait",
            payment.loading && "cursor-not-allowed"
          )}
          style={
            payment.loading || state.loading || disabled
              ? null
              : { color: theme.accent, backgroundColor: theme.primary }
          }
          disabled={payment.loading || state.loading || disabled}
        >
          {state.loading ? (
            <div className="inline-flex align-top flex-nowrap space-x-2 items-center">
              <Loader className="animate-spin h-5 w-5" />
              <span>Placing Order</span>
            </div>
          ) : (
            <span>Pay now</span>
          )}
        </button>
      )}

      {paymentError && (
        <div className="mt-2 font-bold text-red-500" role="alert">
          {paymentError}
        </div>
      )}
    </form>
  );
}
