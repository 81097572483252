import { useOrder } from "context/Order";
import { useTheme } from "context/ThemeContext";

export default function OrderTelephone(props) {
  const theme = useTheme();

  const { order, setTelephone } = useOrder();

  const handleInputChange = (e) => setTelephone(e.target.value);

  if (order.takeaway !== "delivery") return null;

  return (
    <div className="border-t-3 border-gray-100 py-2">
      <label htmlFor="notes" className="block mb-1 text-lg font-bold">
        Phone Number:
      </label>

      {props.error && <div className="mb-2 text-red-500">{props.error}</div>}

      <input
        type="tel"
        id="customerName"
        value={order.telephone}
        onChange={handleInputChange}
        className="block mb-2 w-full p-2 rounded appearance-none focus:outline-none border-2 border-purple-600"
        style={{ borderColor: theme.primary }}
        placeholder="Phone number"
      />
    </div>
  );
}
