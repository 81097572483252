import { useVenue } from "context/VenueContext";
import { formatAddress } from "lib/helper";

export default function VenueInfo() {
  const venue = useVenue();

  return (
    <>
      <h2 className="font-bold">{venue.name}</h2>

      <p className="mb-4 text-sm">{formatAddress(venue)}</p>
    </>
  );
}
