import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import omit from "lodash/omit";
import partition from "lodash/partition";
import sortBy from "lodash/sortBy";
import formatOpeningTimes from "./openingTimes";

import { db } from "lib/firebase";

export default function useVenue(id) {
  if (!id) return [null, false];

  const ref = db.collection("venues").doc(id);

  // Fetch Venue
  const [venue, loadingVenue] = useDocumentData(ref, {
    idField: "id",
    refField: "ref",
  });

  // Fetch Categories
  const [allCategories, loadingCategories] = useCollectionData(
    ref.collection("categories").orderBy("order"),
    { idField: "id", refField: "ref" }
  );

  // Fetch Products
  const [products, loadingProducts] = useCollectionData(
    ref.collection("products").where("active", "==", true),
    { idField: "id" }
  );

  // Fetch Tables
  const [tables, loadingTables] = useCollectionData(
    ref.collection("tables").where("active", "==", true),
    { idField: "id" }
  );

  // Venue doesn't exist
  if (!venue && !loadingVenue) return [null, false];

  // Loading state
  let loading = [
    loadingVenue,
    loadingCategories,
    loadingProducts,
    loadingTables,
  ];

  // Check everything has loaded
  let loaded = loading.every((val) => !val);

  // Loading Document & Collections
  if (!loaded) return [null, true];

  const data = formatVenue(venue, allCategories, products, tables);

  return [data, false];
}

function formatVenue(venue, allCategories, products, tables) {
  // Venue Details
  const data = formatVenueDetails(venue);

  // Products
  data.products = formatVenueProducts(products);

  // Categories / Subcategories
  const [categories, subcategories] = formatVenueCategories(
    allCategories,
    data.products
  );
  data.categories = categories;
  data.subcategories = subcategories;

  // Tables
  data.tables = tables;

  return data;
}

function formatVenueDetails(data) {
  // Venue Details
  let venue = omit(data, [
    "notes",
    "payments.stripe.secret",
    "payments.paymentsense.gatewayUsername",
    "payments.paymentsense.gatewayPassword",
    "payments.paymentsense.JWT",
    "inactive",
  ]);

  // Opening Times
  venue = formatOpeningTimes(venue);

  return venue;
}

function formatVenueProducts(data) {
  // Remove Out of Stock Products
  let products = removeOutOfStockProducts(data);

  // Sort Products by order
  products = sortBy(products, ["order"]);

  return products;
}

function formatVenueCategories(data, products) {
  // Split data into categories/subcategories
  let [categories, subcategories] = partition(data, ["parent", null]);

  // Remove empty categories
  subcategories = removeEmptySubcategories(subcategories, products);

  // Remove categories with no subcategories
  categories = removeEmptyCategories(categories, subcategories);

  // Remove disabled categories
  categories = categories.filter(
    (cat) => !cat.hasOwnProperty("active") || cat.active === true
  );

  return [categories, subcategories];
}

function removeOutOfStockProducts(products) {
  return products.filter(
    (p) => !p.manage_stock || (p.manage_stock && p.quantity > 0)
  );
}

function removeEmptySubcategories(categories, products) {
  return categories.filter((cat) => {
    return products.some((p) => p.category.id === cat.id);
  });
}

function removeEmptyCategories(categories, subcategories) {
  return categories.filter((cat) => {
    return subcategories.some((s) => s.parent.id === cat.id);
  });
}
