import { useEffect, useRef } from "react";
import autosize from "autosize";
import { useOrder } from "context/Order";
import { useTheme } from "context/ThemeContext";

const OrderNotes = () => {
  const theme = useTheme();

  const ref = useRef(null);

  const { order, setNotes } = useOrder();

  useEffect(() => {
    if (ref.current) autosize(ref.current);
  }, [ref]);

  const handleInputChange = (e) => setNotes(e.target.value);

  return (
    <div className="border-t-3 border-gray-100 py-2">
      <label htmlFor="notes" className="block mb-1 text-lg font-bold">
        Add notes:
      </label>
      <textarea
        ref={ref}
        id="notes"
        value={order.notes}
        onChange={handleInputChange}
        className="block w-full p-2 rounded appearance-none focus:outline-none border-2 border-purple-600"
        style={{ borderColor: theme.primary }}
      />
    </div>
  );
};

export default OrderNotes;
