import { useOrder } from "context/Order";
import { groupBy } from "lodash";
import BasketListItem from "./BasketListItem";

const BasketList = () => {
  const { order } = useOrder();

  // Group Items by Category
  const items = groupBy(order.items, (i) => i.category);

  return Object.entries(items).map(([category, items], index) => {
    // Total count of quantity in category
    const count = items.reduce((acc, curr) => acc + curr.quantity, 0);

    return (
      <div key={`category-${index}`} className="mb-4">
        <h3 className="font-bold text-lg mb-2">
          {category} ({count})
        </h3>

        <ul>
          {items.map((item, itemIndex) => (
            <li key={`item-${index}-${itemIndex}`}>
              <BasketListItem item={item} />
            </li>
          ))}
        </ul>
      </div>
    );
  });
};

export default BasketList;
