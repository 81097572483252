import React, { useContext } from "react";
import get from "lodash/get";
import { useVenue } from "./VenueContext";

const ThemeContext = React.createContext();

const initialColors = {
  primary: "#8E24AA",
  accent: "#ffffff",
};

const Provider = ({ children }) => {
  const venue = useVenue();

  const colors = get(venue, "colors", initialColors);

  return (
    <ThemeContext.Provider value={{ ...colors }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const Consumer = ThemeContext.Consumer;

export const useTheme = () => useContext(ThemeContext);

export default Provider;
