import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import get from "lodash/get";
import { functions } from "lib/firebase";
import { useVenue } from "context/VenueContext";
import { useOrder } from "context/Order";
import Bugsnag from "@bugsnag/js";

const stripeConnectPaymentIntent = functions.httpsCallable(
  "stripeConnectPaymentIntent"
);

let promise;

const getStripe = (key) => {
  if (!promise) promise = loadStripe(key);

  return promise;
};

export default function useStripeConnect() {
  let isMounted = true;

  const venue = useVenue();
  const { order } = useOrder();

  const key = get(venue, "payments.stripeConnect.publishable");

  const [state, setState] = useState({
    loading: true,
    clientSecret: null,
    error: null,
  });

  useEffect(() => {
    const fetchClientSecret = async () => {
      stripeConnectPaymentIntent({
        venue: venue.id,
        amount: order.total,
      })
        .then((res) => {
          if (isMounted) {
            setState({
              loading: false,
              clientSecret: res.data.clientSecret,
            });
          }
        })
        .catch((error) => {
          // Bugsnag.notify(error); // TODO uncomment

          setState({
            loading: false,
            error: "Payment via Stripe is unavailable right now.",
          });
        });
    };

    fetchClientSecret();

    return () => {
      isMounted = false;
    };
  }, []);

  return {
    ...state,
    stripePromise: getStripe(key),
  };
}
