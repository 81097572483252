import ReactDOM from "react-dom";
import { AnimatePresence, motion } from "framer-motion";

const Drawer = (props) => {
  const render = (
    <AnimatePresence exitBeforeEnter>
      {props.open && (
        <>
          <div className="fixed z-0 inset-0 w-full h-full bg-black" />

          <motion.div
            className="fixed z-20 inset-0 w-full h-full bg-black"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            onClick={props.onClose}
          />
          <motion.div
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            exit={{ y: "100%" }}
            transition={{ duration: 0.2 }}
            className="fixed z-30 bottom-0 w-full max-h-3/4 flex flex-col p-4 rounded-t-lg bg-white overflow-hidden"
          >
            {props.children}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );

  return ReactDOM.createPortal(render, document.getElementById("root"));
};

export default Drawer;
