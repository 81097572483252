import { Elements } from "@stripe/react-stripe-js";
import useStripeConnect from "./useStripeConnect";
import CheckoutForm from "./CheckoutForm";
import { ReactComponent as Loader } from "./CheckoutForm/loader.svg";

export default function StripeConnect() {
  const { loading, clientSecret, stripePromise, error } = useStripeConnect();

  if (loading)
    return (
      <div className="inline-flex flex-nowrap space-x-2 items-center py-2 px-4 rounded-xl font-bold uppercase text-center text-base appearance-none border-3 border-transparent">
        <Loader className="animate-spin h-5 w-5" />
        <span>Loading</span>
      </div>
    );

  if (error) {
    return (
      <div className="mt-2 font-bold text-red-500" role="alert">
        {error}
      </div>
    );
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <CheckoutForm />
    </Elements>
  );
}
