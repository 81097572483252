import { useOrder } from "context/Order";
import { formatCurrency } from "lib/helper";

export default function DeliveryCost() {
  const { order } = useOrder();

  if (order.takeaway !== "delivery") return null;

  return (
    <div className="flex items-start space-x-2 mb-4">
      <div className="flex-1 leading-tight">
        <h3 className="font-bold"> Delivery Cost</h3>
      </div>

      <div className="flex-initial">
        <span className="font-bold leading-none">
          {order.deliveryCost === 0
            ? "FREE"
            : formatCurrency(order.deliveryCost)}
        </span>
      </div>
    </div>
  );
}
