import { useEffect, useReducer } from "react";
import find from "lodash/find";
import { useVenue } from "context/VenueContext";
import Navigation from "./Navigation";
import SubNavigation from "./SubNavigation";
import Products from "./Products";
import reducer, { initialState } from "./reducer";

const Menu = () => {
  const { categories, subcategories } = useVenue();

  const [state, dispatch] = useReducer(
    reducer,
    initialState(categories, subcategories)
  );

  const handleSelectCategory = (id) => {
    dispatch({ type: "SELECT_CATEGORY", category: id });
  };

  const handleSelectSubcategory = (id) => {
    dispatch({ type: "SELECT_SUBCATEGORY", subcategory: id });
  };

  useEffect(() => {
    // There's an active subcategory...
    if (state.subcategory) {
      const active = find(subcategories, ["id", state.subcategory]);

      // But the subcategory is no longer available...
      if (!active) {
        // Go back to first subcategory of current category
        dispatch({ type: "SELECT_CATEGORY", category: state.category });
      }
    }
  }, [subcategories.length]);

  useEffect(() => {
    // There's an active category...
    if (state.category) {
      const active = find(categories, ["id", state.category]);

      // But the category is no longer available...
      if (!active) {
        // Go back to first category of current category
        dispatch({ type: "SELECT_CATEGORY", category: categories[0].id });
      }
    }
  }, [categories.length]);

  return (
    <>
      <Navigation active={state.category} onSelect={handleSelectCategory} />

      {state.category && (
        <SubNavigation
          category={state.category}
          subcategory={state.subcategory}
          onSelect={handleSelectSubcategory}
        />
      )}

      {state.subcategory && (
        <Products category={state.category} subcategory={state.subcategory} />
      )}
    </>
  );
};

export default Menu;
