import { useReducer } from "react";
import head from "lodash/head";
import { useOrder } from "context/Order";
import reducer from "./reducer";
import ProductVariants from "./ProductVariants";
import ProductExtras from "./ProductExtras";
import AddToBasket from "./AddToBasket";
import { getProductMaxStock } from "lib/product-stock";
import { useVenue } from "context/VenueContext";

const ProductOverlay = (props) => {
  const venue = useVenue();
  const { order, addItem } = useOrder();

  const variant = head(props.product.variants);

  const initialState = {
    id: props.product.id,
    name: props.product.name,
    category: props.category.name,
    subcategory: props.subcategory.name,
    variant: variant ? variant.name : null,
    price: variant ? variant.price : props.product.price,
    quantity: 1,
    total: variant ? variant.price : props.product.price,
    extras: [],
  };

  const [state, dispatch] = useReducer(reducer, {
    variant,
    item: initialState,
  });

  const handleVariantChange = (variant) => {
    dispatch({ type: "CHANGE_VARIANT", variant });
  };

  const handleExtraChange = (extra, checked) => {
    const type = checked ? "REMOVE_EXTRA" : "ADD_EXTRA";

    dispatch({ type, extra });
  };

  const handleQuantity = (value) => {
    dispatch({ type: "SET_QUANTITY", quantity: value });
  };

  const handleSubmit = () => {
    addItem(state.item);
    props.onClose();
  };

  return (
    <>
      <header className="flex-initial relative p-3 pt-0">
        <h2 className="font-bold text-2xl leading-tight pr-8">
          {props.product.name}
        </h2>

        <CloseOverlayButton onClose={props.onClose} />
      </header>

      {!venue.isOpen ? (
        <div className="px-3">{venue.name} is not accepting orders now.</div>
      ) : (
        <>
          <div className="flex-1 overflow-y-auto">
            {props.product.variants.length > 0 && (
              <ProductVariants
                id={props.product.id}
                variants={props.product.variants}
                selected={state.variant}
                onChange={handleVariantChange}
              />
            )}

            {props.product.extras.length > 0 && (
              <ProductExtras
                id={props.product.id}
                extras={props.product.extras}
                selected={state.item.extras}
                onChange={handleExtraChange}
              />
            )}
          </div>

          <div className="flex-initial">
            <AddToBasket
              quantity={state.item.quantity}
              max={getProductMaxStock(props.product, order)}
              onQuantityChange={handleQuantity}
              onSubmit={handleSubmit}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ProductOverlay;

const CloseOverlayButton = (props) => {
  return (
    <button
      type="button"
      onClick={props.onClose}
      className="absolute top-0 right-2 w-8 h-8 flex items-center justify-center focus:outline-none"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className="w-5 text-gray-500"
      >
        <path
          fill="currentColor"
          d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"
        />
      </svg>
    </button>
  );
};
