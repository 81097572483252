import Modal from "components/Modal";
import { useVenue } from "context/VenueContext";
import { useTheme } from "context/ThemeContext";
import { useModal } from "context/ModalContext";
import { useEffect } from "react";
import { ReactComponent as ThumbsUp } from "./thumbs-up.svg";
import { ReactComponent as Loader } from "./loader.svg";

const OrderConfirmModal = (props) => {
  const venue = useVenue();
  const theme = useTheme();
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    openModal();
  }, []);

  const handleClose = () => {
    closeModal();
    props.onClose();
  };

  return (
    <Modal open onClose={handleClose}>
      <div className="relative p-4 text-center overflow-hidden">
        <h2 className="mb-2 text-2xl font-bold">Confirm Order</h2>

        <ThumbsUp className="block w-28 mx-auto mb-2" />

        {venue?.takeaway ? (
          <p className="mb-4 text-lg leading-tight font-bold">
            Place this order with {venue.name}.
          </p>
        ) : (
          <p className="mb-4 text-lg leading-tight font-bold">
            By placing this order you agree that you are present in {venue.name}{" "}
            and over 18 years old
          </p>
        )}

        <div className="flex justify-center space-x-4">
          {props.loading ? (
            <div className="inline-flex flex-nowrap space-x-2 items-center py-2 px-4 rounded-xl font-bold uppercase text-center text-base appearance-none border-3 border-transparent">
              <Loader className="animate-spin h-5 w-5" />
              <span>Placing Order</span>
            </div>
          ) : (
            <>
              <button
                type="button"
                onClick={handleClose}
                className="inline-block py-2 px-4 rounded-xl font-bold uppercase text-center text-base appearance-none border-3 border-purple-600 focus:outline-none"
                style={{ borderColor: theme.primary }}
              >
                Cancel
              </button>

              <button
                type="button"
                onClick={props.onSubmit}
                disabled={props.loading}
                className="inline-flex flex-nowrap space-x-2 items-center py-2 px-4 rounded-xl font-bold uppercase text-center text-base appearance-none border-3 focus:outline-none border-purple-600 text-white bg-purple-600"
                style={{
                  color: theme.accent,
                  backgroundColor: theme.primary,
                  borderColor: theme.primary,
                }}
              >
                Place Order
              </button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OrderConfirmModal;
