import { AnimatePresence, motion } from "framer-motion";
import { useVenue } from "context/VenueContext";
import { useTheme } from "context/ThemeContext";

export default function ClosedBanner() {
  const venue = useVenue();
  const theme = useTheme();

  return (
    <AnimatePresence>
      {!venue.isOpen && (
        <motion.div
          initial={{ y: "-100%", height: 0 }}
          animate={{ y: "0%", height: "auto" }}
          exit={{ y: "-100%", height: 0 }}
          transition={{ duration: 0.2, delay: 0.1 }}
          className="overflow-hidden"
        >
          <div
            role="alert"
            className="p-3 font-bold text-center text-white bg-purple-600"
            style={{ color: theme.accent, backgroundColor: theme.primary }}
          >
            {venue.name} is not accepting orders now.
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
