import clsx from "clsx";
import { useTheme } from "context/ThemeContext";
import { useVenue } from "context/VenueContext";

const Navigation = (props) => {
  const theme = useTheme();
  let { categories } = useVenue();

  categories = categories.filter((cat) => !cat.parent);

  return (
    <ul className="mb-1 flex space-x-2 py-1 w-full overflow-x-auto no-scrollbar">
      {categories.map((category) => {
        const isActive = props.active === category.id;

        return (
          <li key={category.id}>
            <button
              onClick={() => props.onSelect(category.id)}
              className={clsx(
                "inline-block px-4 py-2.5 whitespace-nowrap rounded-2xl font-bold leading-snug uppercase border-2 border-purple-600 transition focus:outline-none",
                isActive && "text-white bg-purple-600"
              )}
              style={{
                borderColor: theme.primary,
                color: isActive ? theme.accent : "inherit",
                backgroundColor: isActive ? theme.primary : "inherit",
              }}
            >
              {category.name}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default Navigation;
