import { useOrder } from "context/Order";
import { useVenue } from "context/VenueContext";
import { formatCurrency } from "lib/helper";

export default function Takeaway() {
  const venue = useVenue();
  const { order, setTakeawayType } = useOrder();

  const canDeliver = venue?.delivery?.enabled;
  const canCollect = venue?.collection?.enabled;

  const isDelivery = order.takeaway === "delivery";

  const onTakeawayChange = () => {
    setTakeawayType(isDelivery ? "collection" : "delivery");
  };

  if (canDeliver && isDelivery) {
    return (
      <Delivery
        delivery={venue.delivery}
        canCollect={canCollect}
        onChange={onTakeawayChange}
      />
    );
  }

  if (venue?.collection?.enabled) {
    return (
      <Collection
        collection={venue.collection}
        canDeliver={canDeliver}
        onChange={onTakeawayChange}
      />
    );
  }

  return null;
}

function Delivery({ delivery, canCollect, onChange }) {
  const cost = Number(delivery.cost);
  const minOrder = Number(delivery.minOrder);

  return (
    <section className="mb-4 rounded-lg text-sm bg-gray-100">
      <div className="p-3 text-center">
        <h4 className="block font-bold">{`Delivers in ${delivery.startTime}-${delivery.endTime} mins`}</h4>

        {canCollect && (
          <button type="button" onClick={onChange} className="underline">
            I want to collect
          </button>
        )}
      </div>

      <div className="border-t border-gray-300 flex flex-nowrap justify-between">
        <p className="p-3">
          {cost === 0 ? "Free Delivery" : `${formatCurrency(cost)} delivery`}
        </p>
        <p className="p-3">
          {minOrder > 0 && (
            <span>
              <b>{formatCurrency(minOrder)}</b> min. order
            </span>
          )}
        </p>
      </div>
    </section>
  );
}

function Collection({ collection, canDeliver, onChange }) {
  return (
    <section className="mb-4 rounded-lg text-sm bg-gray-100">
      <div className="p-3 text-center">
        <h4 className="block font-bold">{`Collect in ${collection.startTime}-${collection.endTime} mins`}</h4>

        {canDeliver && (
          <button type="button" onClick={onChange} className="underline">
            I want it delivered
          </button>
        )}
      </div>
    </section>
  );
}
