import React, { useContext, useMemo } from "react";
import { matchPath, useLocation } from "react-router";
import { AnimatePresence, motion } from "framer-motion";
import useFetchVenue from "./use-venue";
import Loader from "components/Loader";

const getVenueID = (pathname) => {
  const match = matchPath(pathname, {
    path: "/venues/:id",
    exact: true,
    strict: false,
  });

  // Check match, else check local storage
  const id = match ? match.params.id : window.localStorage.getItem("venue");

  window.localStorage.setItem("venue", id);

  return id;
};

const VenueContext = React.createContext();

const Provider = ({ children }) => {
  const location = useLocation();

  const id = useMemo(() => getVenueID(location.pathname), []);

  const [venue, loading] = useFetchVenue(id);

  return (
    <VenueContext.Provider value={venue}>
      <AnimatePresence initial={false}>
        <motion.div
          key={`venue-${loading ? "loading" : "loaded"}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4 }}
        >
          {loading ? <Loader /> : children}
        </motion.div>
      </AnimatePresence>
    </VenueContext.Provider>
  );
};

export const Consumer = VenueContext.Consumer;

export const useVenue = () => useContext(VenueContext);

export default Provider;
