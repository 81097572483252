import find from "lodash/find";
import { useOrder } from "context/Order";
import { useVenue } from "context/VenueContext";
import { formatCurrency } from "lib/helper";
import { getProductMaxStock } from "lib/product-stock";
import QuantitySelector from "./QuantitySelector";
import BasketListItemExtras from "./BasketListItemExtras";

const BasketListItem = ({ item }) => {
  const { products } = useVenue();
  const { order, removeItem } = useOrder();

  const product = find(products, ["id", item.id]);

  if (!product) return null;

  const stock = getProductMaxStock(product, order);
  // Remaining stock shouldn't include current stock of this item
  if (product.manage_stock) stock.remaining += item.quantity;

  return (
    <div className="flex items-start space-x-2 mb-4">
      <div className="flex-initial">
        <QuantitySelector quantity={item.quantity} max={stock} item={item} />
      </div>

      <div className="flex-1 leading-tight">
        <h3 className="font-bold">{item.name}</h3>

        {item.variant && <p>{item.variant}</p>}

        {item.extras && <BasketListItemExtras extras={item.extras} />}
      </div>

      <div className="flex-initial">
        <span className="font-bold leading-none">
          {formatCurrency(item.total)}
        </span>
      </div>

      <div className="flex-initial">
        <button
          type="button"
          onClick={() => removeItem(item)}
          className="flex items-center justify-center w-6 h-6 ml-2 text-red-500 focus:outline-none"
        >
          <svg viewBox="0 0 24 24" className="w-5">
            <path
              fill="currentColor"
              d="M9 19c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5-17v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712zm-3 4v16h-14v-16h-2v18h18v-18h-2z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default BasketListItem;
