import Drawer from "components/Drawer";
import Product from "./Product";
import ProductOverlay from "./ProductOverlay";
import useProducts from "./use-products";

const Products = (props) => {
  const [products, active, category, subcategory, actions] = useProducts(props);

  return (
    <>
      <ul className="space-y-3">
        {products.map((p) => (
          <li key={p.id}>
            <Product {...p} onOpen={() => actions.openOverlay(p.id)} />
          </li>
        ))}
      </ul>

      <Drawer open={!!active} onClose={actions.closeOverlay}>
        {active && (
          <ProductOverlay
            product={active}
            category={category}
            subcategory={subcategory}
            onClose={actions.closeOverlay}
          />
        )}
      </Drawer>
    </>
  );
};

export default Products;
