import { round, without, isEqual, omit } from "lodash";

export const initialState = {
  order: {
    notes: "",
    status: "new",
    table: "",
    subtotal: 0,
    tip: 0,
    total: 0,
    payment: null,
    items: [],
    customerName: "",
    telephone: "",
  },
  pastOrder: null,
  complete: null,
};

export default function reducer(state, action) {
  let items, subtotal, total, tip, deliveryCost;

  switch (action.type) {
    case "ADD_ITEM":
      items = combineOrderItems([...state.order.items, action.item]);
      subtotal = calculateOrderSubtotal(items);
      total = round(subtotal + (state.order.tip || 0), 2);

      return {
        ...state,
        order: {
          ...state.order,
          tip: 0,
          items,
          subtotal,
          total,
        },
      };

    case "UPDATE_ITEM":
      items = state.order.items.map((item) => updateOrderItem(item, action));
      subtotal = calculateOrderSubtotal(items);
      tip = subtotal !== state.order.subtotal ? 0 : state.order.tip || 0;
      total = round(subtotal + tip, 2);

      return {
        ...state,
        order: {
          ...state.order,
          items,
          tip,
          subtotal,
          total,
        },
      };

    case "REMOVE_ITEM":
      items = without(state.order.items, action.item);
      subtotal = calculateOrderSubtotal(items);
      total = subtotal;

      return {
        ...state,
        order: {
          ...state.order,
          tip: 0,
          items,
          subtotal,
          total,
        },
      };

    case "SET_ITEMS":
      items = action.items;
      subtotal = calculateOrderSubtotal(items);
      tip = subtotal !== state.order.subtotal ? 0 : state.order.tip || 0;
      total = round(subtotal + tip, 2);

      return {
        ...state,
        order: {
          ...state.order,
          items,
          tip,
          subtotal,
          total,
        },
      };

    case "SET_TIP":
      tip = round(action.tip || 0, 2);
      total = round(tip + state.order.subtotal, 2);

      return {
        ...state,
        order: {
          ...state.order,
          tip,
          total,
        },
      };

    case "SET_TABLE_NUMBER":
      return {
        ...state,
        order: {
          ...state.order,
          table: action.table,
        },
      };

    case "SET_NOTES":
      return {
        ...state,
        order: {
          ...state.order,
          notes: action.notes,
        },
      };

    case "SET_PAST_ORDER":
      return {
        ...state,
        order: { ...state.pastOrder },
      };

    case "COMPLETE_ORDER":
      return {
        ...initialState,
        pastOrder: { ...state.order, table: "" },
        complete: action.orderID,
      };

    case "POST_COMPLETE_ORDER":
      return {
        ...state,
        complete: null,
      };

    case "CLEAR_ORDER":
      return {
        ...state,
        order: { ...initialState.order },
        complete: null,
      };

    case "SET_TAKEAWAY_TYPE":
      return {
        ...state,
        order: {
          ...state.order,
          takeaway: action.takeaway,
        },
      };

    case "SET_ADDRESS":
      return {
        ...state,
        order: {
          ...state.order,
          address: action.address,
        },
      };

    case "SET_TELEPHONE":
      return {
        ...state,
        order: {
          ...state.order,
          telephone: action.telephone,
        },
      };

    case "SET_CUSTOMER_NAME":
      return {
        ...state,
        order: {
          ...state.order,
          customerName: action.name,
        },
      };

    case "SET_DELIVERY":
      deliveryCost = round(action.cost || 0, 2);

      return {
        ...state,
        order: {
          ...state.order,
          deliveryCost,
        },
      };
  }
}

/**
 * Calculate the subtotal of all order items (subtotal)
 *
 * @param {array} items Order Items
 */
function calculateOrderSubtotal(items) {
  const orderTotal = items.reduce((total, item) => total + item.total, 0);

  return round(orderTotal, 2);
}

/**
 * Update an order item object
 *
 * @param {object} item Order Item
 * @param {object} action Reducer actions
 *
 * @return {object} Updated Order Item
 */
function updateOrderItem(item, action) {
  if (isEqual(item, action.item)) {
    let itemObj = { ...action.item, [action.key]: action.value };

    itemObj.total = calculateOrderItemTotal(itemObj);

    return itemObj;
  }

  return item;
}

/**
 * Calculate the total of a single order item
 *
 * @param {object} item Order item
 */
function calculateOrderItemTotal(item) {
  // Calculate total of all extras so far
  let extras = item.extras.reduce((acc, curr) => acc + curr.price, 0);
  extras = round(extras, 2);

  // Total price = (price + extras) * quantity
  return round((item.price + extras) * item.quantity, 2);
}

/**
 * Check for any exact matches in items and merge, updating quantity & total
 *
 * @param {array} items All order items
 *
 * @returns {array} New array of combined order items
 */
function combineOrderItems(items) {
  return items.reduce((acc, curr) => {
    const currObj = omit(curr, ["quantity", "total"]);

    let match = false;

    acc = acc.map((item) => {
      if (isEqual(currObj, omit(item, ["quantity", "total"]))) {
        match = true;

        return {
          ...item,
          quantity: item.quantity + curr.quantity,
          total: round(item.total + curr.total, 2),
        };
      }

      return item;
    });

    return match ? acc : [...acc, curr];
  }, []);
}
