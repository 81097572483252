import { useEffect, useState } from "react";
import toNumber from "lodash/toNumber";
import inRange from "lodash/inRange";
import { useTheme } from "context/ThemeContext";

const QuantitySelector = (props) => {
  const theme = useTheme();

  const [value, setValue] = useState(props.quantity);

  useEffect(() => {
    setValue(props.quantity);
  }, [props.quantity]);

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);

    const quantity = toNumber(value);
    const stockAvailable = inRange(quantity, 1, props.max.remaining + 1);

    if (stockAvailable) props.onQuantityChange(quantity);
  };

  const handleBlur = () => {
    const quantity = toNumber(value);
    const stockAvailable = inRange(quantity, 1, props.max.remaining + 1);

    if (!stockAvailable) setValue(props.quantity);
  };

  return (
    <div className="inline-flex flex-nowrap space-x-2 max-w-full mb-3">
      <button
        type="button"
        disabled={props.quantity < 2}
        onClick={() => props.onQuantityChange(props.quantity - 1)}
        className="w-12 h-12 rounded-lg font-bold text-3xl transition text-white bg-purple-600 focus:outline-none disabled:opacity-50"
        style={{ color: theme.accent, backgroundColor: theme.primary }}
      >
        -
      </button>

      <input
        type="number"
        pattern="[0-9]*"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        className="w-14 h-12 text-center border-3 border-purple-600 rounded-lg font-bold text-2xl appearance-none no-spin-button focus:outline-none"
        style={{ borderColor: theme.primary }}
      />

      <button
        type="button"
        disabled={props.quantity >= props.max.remaining}
        onClick={() => props.onQuantityChange(props.quantity + 1)}
        className="w-12 h-12 rounded-lg font-bold text-3xl transition text-white bg-purple-600 focus:outline-none disabled:opacity-50"
        style={{ color: theme.accent, backgroundColor: theme.primary }}
      >
        +
      </button>
    </div>
  );
};

export default QuantitySelector;
