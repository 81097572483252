import { useState } from "react";
import { usePayment } from "context/PaymentContext";
import { ReactComponent as Loader } from "./loader.svg";
import { useTheme } from "context/ThemeContext";

const Cash = () => {
  const theme = useTheme();
  const payment = usePayment();

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    payment.handleSubmit();
  };

  return (
    <div>
      {loading ? (
        <div className="inline-flex flex-nowrap space-x-2 items-center py-2 px-4 rounded-xl font-bold uppercase text-center text-base appearance-none border-3 border-transparent">
          <Loader className="animate-spin h-5 w-5" />
          <span>Placing Order</span>
        </div>
      ) : (
        <button
          type="button"
          disabled={payment.loading}
          onClick={handleSubmit}
          className="block w-full py-2.5 px-4 leading-snug rounded-lg font-bold uppercase select-none text-black transition border-2 border-purple-600 focus:outline-none disabled:bg-gray-200 disabled:border-gray-200 disabled:cursor-not-allowed"
          style={payment.loading ? null : { borderColor: theme.primary }}
        >
          Pay at Table
        </button>
      )}
    </div>
  );
};

export default Cash;
