import ReactDOM from "react-dom";
import { AnimatePresence, motion } from "framer-motion";

const Modal = (props) => {
  const render = (
    <AnimatePresence exitBeforeEnter>
      {props.open && (
        <>
          <div
            className="fixed z-20 inset-0 w-full h-full bg-transparent"
            onClick={props.onClose}
          />

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed z-30 inset-0 overflow-y-auto flex items-center p-4"
            role="dialog"
          >
            <div onClick={props.onClose} className="block fixed inset-0" />

            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 40 }}
              transition={{ duration: 0.2 }}
              className="relative z-10 m-auto max-w-2xl w-full bg-white rounded-xl text-left overflow-hidden shadow-xl"
            >
              {props.children}
            </motion.div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );

  return ReactDOM.createPortal(render, document.getElementById("root"));
};

export default Modal;
