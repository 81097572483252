import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { get } from "lodash";
import { useTheme } from "context/ThemeContext";
import { useOrder } from "context/Order";
import { formatCurrency, pluralize } from "lib/helper";

const BasketButton = (props) => {
  const { order } = useOrder();

  const items = get(order, "items", []);
  const count = items.reduce((acc, curr) => acc + curr.quantity, 0);

  const total = order.total + (order?.deliveryCost || 0);

  return (
    <AnimatePresence initial={false}>
      {count > 0 && (
        <motion.div
          initial={{ y: "100%", height: 0 }}
          animate={{ y: "0%", height: "auto" }}
          exit={{ y: "100%", height: 0 }}
          transition={{ duration: 0.2, delay: 0.1 }}
        >
          <BasketButtonType {...props}>
            <div>{props.text}</div>

            <AnimatePresence exitBeforeEnter initial={false}>
              <motion.div
                key={total}
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
                transition={{ duration: 0.3, delay: 0.1 }}
              >
                {formatCurrency(total)} / {pluralize(count, "item")}
              </motion.div>
            </AnimatePresence>
          </BasketButtonType>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BasketButton;

const BasketButtonType = (props) => {
  const theme = useTheme();

  const className =
    "relative z-20 block w-full p-3 font-bold text-center text-lg leading-normal uppercase text-white bg-purple-600 focus:outline-none";

  const style = { color: theme.accent, backgroundColor: theme.primary };

  if (props.to) {
    return (
      <Link to="/checkout" className={className} style={style}>
        {props.children}
      </Link>
    );
  }

  return (
    <button
      type="button"
      className={className}
      style={style}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};
