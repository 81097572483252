import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import { useOrder } from "context/Order";
import { usePayment } from "context/PaymentContext";
import { ReactComponent as Loader } from "../loader.svg";
import CARD_STYLES from "./styles";
import { useTheme } from "context/ThemeContext";

const PaymentsenseCheckout = (props) => {
  const theme = useTheme();
  const { order } = useOrder();
  const payment = usePayment();

  const ref = useRef(null);
  const connectE = useRef(null);

  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [address, setAddress] = useState({ address1: "", postcode: "" });

  // Mount iframe when access token changes
  useEffect(() => {
    setMounted(false);

    ref.current.innerHTML = "";

    connectE.current = new Connect.ConnectE(
      {
        containerId: "paymentsenseContainer",
        paymentDetails: {
          amount: Math.round(order.total * 100).toString(),
          currencyCode: "826", // GBP
          paymentToken: props.token,
        },
        onIframeLoaded: () => setMounted(true),
        styles: CARD_STYLES,
      },
      setErrors
    );
  }, [props.token]);

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  };

  const handleAddressValidation = (e) => {
    const { name, placeholder } = e.target;

    let errorState = errors.filter((e) => e.errorType !== name);

    if (isEmpty(address[name])) {
      errorState = [
        ...errorState,
        {
          errorType: name,
          message: `Please enter the ${placeholder.toLowerCase()}.`,
        },
      ];
    }

    setErrors(errorState);
  };

  const validateAddress = () => {
    let errors = [];

    if (isEmpty(address.address1)) {
      errors.push({
        errorType: "address1",
        message: "Please enter the address line 1.",
      });
    }

    if (isEmpty(address.postcode)) {
      errors.push({
        errorType: "postcode",
        message: "Please enter the postcode.",
      });
    }

    return !errors.length ? null : errors;
  };

  const isLoading = (value) => {
    setLoading(value);
    payment.setLoading(value);
  };

  const handleSubmit = async () => {
    if (payment.loading) return;

    try {
      isLoading(true);

      // Address Validation
      const invalidAddress = validateAddress();
      if (invalidAddress) throw invalidAddress;

      const data = await connectE.current.executePayment({
        billingAddress: address,
      });

      if (data.statusCode !== 0) throw new Error(data.message);

      payment.handleSubmit({
        provider: "paymentsense",
        order_id: props.orderID,
        id: data.md,
        auth_code: data.authCode,
      });
    } catch (error) {
      isLoading(false);

      if (error instanceof Error) {
        const message = error.message || "The transaction was declined.";
        props.onError(`Payment failed. ${message}`);
      } else {
        setErrors(error);
      }
    }
  };

  const hasErrors = errors.length > 0;

  return (
    <>
      <div id="paymentsenseContainer" ref={ref}></div>

      {mounted && (
        <div className="flex space-x-4">
          <div className="flex-1">
            <Input
              label="Address Line 1"
              name="address1"
              value={address.address1}
              onChange={handleAddressChange}
              onBlur={handleAddressValidation}
              autoComplete="address-line1"
              error={find(errors, (e) => e.errorType === "address1")}
            />
          </div>
          <div className="flex-initial w-24">
            <Input
              label="Postcode"
              name="postcode"
              value={address.postcode}
              onChange={handleAddressChange}
              onBlur={handleAddressValidation}
              autoComplete="postcode"
              error={find(errors, (e) => e.errorType === "postcode")}
            />
          </div>
        </div>
      )}

      {hasErrors && (
        <ul className="font-bold text-red-500">
          {errors.map((error) => (
            <li key={error.errorType} role="alert">
              {error.message}
            </li>
          ))}
        </ul>
      )}

      {mounted ? (
        <button
          type="button"
          onClick={handleSubmit}
          className={clsx(
            "block w-full mt-2 py-3 px-4 leading-snug rounded-lg font-bold uppercase select-none transition text-white bg-purple-600 focus:outline-none disabled:bg-gray-200 disabled:text-black",
            loading && "cursor-wait",
            payment.loading && "cursor-not-allowed"
          )}
          disabled={payment.loading || loading || hasErrors}
          style={
            payment.loading || loading || hasErrors
              ? null
              : { color: theme.accent, backgroundColor: theme.primary }
          }
        >
          {loading ? (
            <div className="inline-flex align-top flex-nowrap space-x-2 items-center">
              <Loader className="animate-spin h-5 w-5" />
              <span>Placing Order</span>
            </div>
          ) : (
            <span>Pay now</span>
          )}
        </button>
      ) : (
        <Loader className="block mx-auto w-8 h-8 animate-spin text-gray-500" />
      )}
    </>
  );
};

export default PaymentsenseCheckout;

const Input = (props) => (
  <>
    <label htmlFor={props.name} className="hidden">
      {props.label}
    </label>
    <input
      type="text"
      id={props.name}
      name={props.name}
      placeholder={props.label}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      required
      autoComplete={props.autoComplete}
      className={clsx(
        "block w-full py-2 px-3 my-1 appearance-none rounded-lg text-base border border-gray-paymentsense placeholder-gray-500 focus:outline-none focus:border-blue-500",
        !isEmpty(props.value) && "border-green-500",
        props.error && "border-red-500"
      )}
    />
  </>
);
