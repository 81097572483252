import { useState } from "react";

const useLocalStorage = (
  key,
  initialState = null,
  ttl = 24 * 60 * 60 * 1000 // 24 hour expiry
) => {
  const now = new Date();

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [state, setState] = useState(() => {
    try {
      // Get from local storage by key
      const itemStr = window.localStorage.getItem(key);

      // No storage, return initialState
      if (!itemStr) return initialState;

      // Parse stored string to JSON.
      const { expiry, ...item } = JSON.parse(itemStr);

      // Check expiry value
      if (!expiry || now.getTime() > expiry) {
        // Item is expired, delete the item from storage
        window.localStorage.removeItem(key);

        return initialState;
      }

      return item;
    } catch (error) {
      // If error also return initialState
      console.error(error);
      return initialState;
    }
  });

  const setLocalState = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(state) : value;

      // Save state
      setState(valueToStore);

      // Save to local storage
      window.localStorage.setItem(
        key,
        JSON.stringify({
          ...valueToStore,
          expiry: now.getTime() + ttl,
        })
      );
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [state, setLocalState];
};

export default useLocalStorage;
