import { useVenue } from "context/VenueContext";
import { useState } from "react";
import Custom from "./Custom";
import Options from "./Options";

const Tips = () => {
  const venue = useVenue();

  const [open, setOpen] = useState(false);

  if (!venue.tips) return null;

  return (
    <div className="border-t-3 border-gray-100 py-2 px-4">
      <h3 className="block mb-1 text-lg font-bold">Add A Tip:</h3>

      {open ? (
        <Custom onClose={() => setOpen(false)} />
      ) : (
        <Options onCustom={() => setOpen(true)} />
      )}
    </div>
  );
};

export default Tips;
