import { useHistory } from "react-router";
import { AnimatePresence, motion } from "framer-motion";
import { useOrder } from "context/Order";
import { useTheme } from "context/ThemeContext";
import { get } from "lodash";

const SameAgainButton = () => {
  const history = useHistory();
  const theme = useTheme();

  const { order, pastOrder, setPastOrder } = useOrder();

  const handlePopulateOrder = () => {
    setPastOrder();
    history.push("/checkout");
  };

  const items = get(order, "items.length", 0);
  const show = items < 1 && pastOrder !== null;

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ y: "100%", height: 0 }}
          animate={{ y: "0%", height: "auto" }}
          exit={{ y: "100%", height: 0 }}
          transition={{ duration: 0.2, delay: 0.1 }}
        >
          <button
            type="button"
            onClick={handlePopulateOrder}
            className="relative z-10 block w-full p-3 font-bold text-center text-lg leading-10 uppercase text-white bg-purple-600 focus:outline-none"
            style={{ color: theme.accent, backgroundColor: theme.primary }}
          >
            Same Again?
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SameAgainButton;
