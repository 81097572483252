import React, { useState, useContext } from "react";
import { motion } from "framer-motion";

const DrawerContext = React.createContext();

const variants = {
  open: { scale: 0.95, borderRadius: "8px" },
  default: { scale: 1, borderRadius: "0px" },
};

const Provider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const openDrawer = () => setOpen(true);

  const closeDrawer = () => setOpen(false);

  return (
    <DrawerContext.Provider value={{ open, openDrawer, closeDrawer }}>
      <motion.div
        className="absolute inset-0 z-10 w-full h-full bg-white overflow-hidden"
        animate={open ? "open" : "default"}
        variants={variants}
        transition={{ duration: 0.2 }}
      >
        {children}
      </motion.div>
    </DrawerContext.Provider>
  );
};

export const Consumer = DrawerContext.Consumer;

export const useDrawer = () => useContext(DrawerContext);

export default Provider;
