import Page from "components/Page";
import Header from "components/Header";
import Takeaway from "components/Takeaway";
import Menu from "components/Menu";
import ClosedBanner from "components/Banners/Closed";
import LastOrdersBanner from "components/Banners/LastOrders";
import BasketButton from "components/BasketButton";
import SameAgainButton from "components/SameAgainButton";
import { useVenue } from "context/VenueContext";

const VenuePage = () => {
  const venue = useVenue();

  return (
    <Page>
      <div className="flex flex-col h-full">
        <ClosedBanner />
        <LastOrdersBanner />

        <div className="flex-1 p-4 overflow-y-auto">
          <Header />

          {venue?.takeaway && <Takeaway />}

          <Menu />
        </div>

        {venue.isOpen && <SameAgainButton />}

        {venue.isOpen && <BasketButton to="/checkout" text="View basket" />}
      </div>
    </Page>
  );
};

export default VenuePage;
