import clsx from "clsx";
import { useTheme } from "context/ThemeContext";
import { formatCurrency } from "lib/helper";

const ProductVariants = (props) => {
  const theme = useTheme();

  return (
    <div className="border-t-3 border-gray-100">
      <div className="p-3">
        <h3 className="mb-2 font-bold text-xl leading-tight">Size</h3>

        <ul className="mb-2 space-y-2">
          {props.variants.map((variant, index) => {
            const isChecked = props.selected === variant;

            return (
              <li key={`product-${props.id}-variant-${index}`}>
                <input
                  id={`variant-${index}`}
                  type="radio"
                  value={index}
                  checked={isChecked}
                  onChange={() => props.onChange(variant)}
                  className="sr-only"
                />

                <label
                  htmlFor={`variant-${index}`}
                  className={clsx(
                    "flex items-center justify-between space-x-2 w-full p-2 text-left rounded-xl font-bold text-lg leading-tight transition border-3 border-purple-600 focus:outline-none",
                    isChecked && "text-white bg-purple-600"
                  )}
                  style={{
                    borderColor: theme.primary,
                    color: isChecked ? theme.accent : "inherit",
                    backgroundColor: isChecked ? theme.primary : "inherit",
                  }}
                >
                  <h4>{variant.name}</h4>
                  <span>{formatCurrency(variant.price)}</span>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ProductVariants;
