import filter from "lodash/filter";
import firebase, { db } from "lib/firebase";

/**
 * Create a new order agasint a venue
 *
 * @param {Object} venue Venue Document
 * @param {Object} order Order data to be stored to the Document
 * @param {String} payment An optional Paymnt object from a gateway (e.g. Stripe)
 *
 * @returns Unqiue OrderID for the order
 */
function placeOrder(venue, order, payment = null) {
  const { order_id: orderID } = venue;

  // Atomic FiedValue incrementing
  const increment = firebase.firestore.FieldValue.increment(1);

  // Get venue DocumentReference
  const venueRef = venue.ref;

  // Create new order DocumentReference
  const orderRef = venueRef.collection("current_orders").doc();

  // New order document data
  const orderDoc = { ...order, date: new Date(), id: orderID, payment };

  // Initialise batch process
  const batch = db.batch();

  // New Order
  batch.set(orderRef, orderDoc);

  // Increment Order ID
  batch.set(venueRef, { order_id: increment }, { merge: true });

  // Commit batch, then return orderID
  return batch.commit().then(() => {
    return { id: orderID, ref: orderRef };
  });
}

/**
 * Queues Print Jobs for new orders, if the venue is printing
 *
 * @param {Object} venue Venue Document
 * @param {DocumentReference} orderRef
 */
async function queuePrintJobs(venue, orderRef) {
  if (!venue.printing.cloudprnt) return null;

  // Get printers set to automatic
  const printers = filter(venue.printers, "automatic");

  for (const printer of printers) {
    await venue.ref.collection("print_queue").add({
      order: orderRef,
      location: printer.location,
      timestamp: new Date(),
    });
  }
}

export { placeOrder, queuePrintJobs };
