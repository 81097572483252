import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import parse from "date-fns/parse";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import isBefore from "date-fns/isBefore";
import isAfter from "date-fns/isAfter";

export default function formatOpeningTimes(venue) {
  const times = get(venue, "times");

  if (!times) return { ...venue, isOpen: true, isLastOrders: false };

  const dayOfWeek = getDayOfWeek();
  const timesToday = get(times, dayOfWeek);

  // Can't find todays object
  if (!timesToday) return { ...venue, isOpen: true, isLastOrders: false };

  // Not open
  if (!timesToday.open) return { ...venue, isOpen: false, isLastOrders: false };

  const now = new Date();

  const opening = parseTime(timesToday.opening, startOfDay(now));
  const closing = parseTime(timesToday.closing, endOfDay(now));
  const lastOrders = parseTime(timesToday.last_orders, endOfDay(now));

  const isOpen = isBetween(now, opening, closing);
  const isLastOrders = isBetween(now, lastOrders, closing);

  return { ...venue, isOpen, isLastOrders };
}

// Get the day of week string
function getDayOfWeek() {
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  return days[new Date().getDay()];
}

// Parse a time string to Date object
function parseTime(string, fallback) {
  if (isEmpty(string)) return fallback;

  return parse(string, "HH:mm", new Date());
}

// Check if a date is between two other dates
function isBetween(date, start, end) {
  return isAfter(date, start) && isBefore(date, end);
}
