import { useEffect, useRef } from "react";
import { useOrder } from "context/Order";
import { useTheme } from "context/ThemeContext";

const CustomOrderTip = () => {
  const theme = useTheme();

  const { order, setTip } = useOrder();

  const ref = useRef(null);

  // Focus input on mount
  useEffect(() => {
    ref.current.focus();
  }, []);

  // Order tip changed, update ref value
  useEffect(() => {
    ref.current.value = order.tip || "";
  }, [order.tip]);

  const handleChange = (e) => {
    let { value } = e.target;

    // Trim to 2 decimal places
    value =
      value.indexOf(".") >= 0
        ? value.substr(0, value.indexOf(".")) +
          value.substr(value.indexOf("."), 3)
        : value;

    ref.current.value = value;

    setTip(value);
  };

  return (
    <label className="block relative">
      <div className="absolute top-0 left-0 flex items-center justify-center w-10 h-12 text-lg font-bold">
        &pound;
      </div>

      <input
        ref={ref}
        type="number"
        pattern="[0-9]*"
        min="0.00"
        step="0.01"
        placeholder="Enter value"
        defaultValue={order.tip || ""}
        onChange={handleChange}
        className="block w-full p-2.5 pl-10 rounded appearance-none focus:outline-none border-2 border-purple-600"
        style={{ borderColor: theme.primary }}
      />
    </label>
  );
};

export default CustomOrderTip;
