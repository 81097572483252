import { ReactComponent as Loader } from "./loader.svg";
import usePaymentsense from "./use-paymentsense";
import PaymentsenseCheckout from "./PaymentsenseCheckout";

const Paymentsense = () => {
  const [token, orderID, loading, error, actions] = usePaymentsense();

  if (loading) {
    return (
      <Loader className="block mx-auto w-8 h-8 animate-spin text-gray-500" />
    );
  }

  if (error) {
    return (
      <div>
        <p className="font-bold text-red-500" role="alert">
          {error}
        </p>
        <button
          type="button"
          onClick={actions.handleRetry}
          className="inline-block py-2.5 px-4 leading-snug underline font-bold uppercase select-none text-black transition focus:outline-none"
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <PaymentsenseCheckout
      token={token}
      orderID={orderID}
      onError={actions.handleError}
    />
  );
};

export default Paymentsense;
