import without from "lodash/without";
import { calculateTotalItemPrice } from "lib/helper";

const reducer = (state, action) => {
  let extras, total;

  switch (action.type) {
    case "CHANGE_VARIANT":
      const { name, price } = action.variant;

      total = calculateTotalItemPrice({ ...state.item, price });

      return {
        ...state,
        variant: action.variant,
        item: {
          ...state.item,
          variant: name,
          price: price,
          total: total,
        },
      };

    case "ADD_EXTRA":
      // Add new extra to array
      extras = [...state.item.extras, action.extra];

      total = calculateTotalItemPrice({ ...state.item, extras });

      return {
        ...state,
        item: {
          ...state.item,
          total: total,
          extras: extras,
        },
      };

    case "REMOVE_EXTRA":
      // Remove extra from array
      extras = without(state.item.extras, action.extra);

      total = calculateTotalItemPrice({ ...state.item, extras });

      return {
        ...state,
        item: {
          ...state.item,
          total: total,
          extras: extras,
        },
      };

    case "SET_QUANTITY":
      let quantity = parseInt(action.quantity, 10);
      quantity = quantity < 1 || isNaN(quantity) ? 1 : quantity;

      total = calculateTotalItemPrice({ ...state.item, quantity });

      return {
        ...state,
        item: {
          ...state.item,
          quantity: quantity,
          total: total,
        },
      };

    default:
      throw new Error();
  }
};

export default reducer;
