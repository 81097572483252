import React, { useContext, useState } from "react";

const PaymentContext = React.createContext();

const Provider = ({ onSubmit, children }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (data = null) => {
    if (loading) return;

    setLoading(true);

    onSubmit(data);
  };

  return (
    <PaymentContext.Provider value={{ loading, setLoading, handleSubmit }}>
      {children}
    </PaymentContext.Provider>
  );
};

export const Consumer = PaymentContext.Consumer;

export const usePayment = () => useContext(PaymentContext);

export default Provider;
