import BackLink from "components/BackLink";
import { useVenue } from "context/VenueContext";

export default function Header() {
  const venue = useVenue();

  return (
    <header className="relative px-9 text-center">
      <BackLink
        to={`/venues/${venue.id}`}
        screenReaderText="Back to Menu"
        className="absolute left-0 flex items-center justify-center w-8 h-8"
      />

      <h1 className="text-xl font-bold">Checkout</h1>
    </header>
  );
}
