import { useEffect } from "react";
import { useHistory } from "react-router";
import Page from "components/Page";
import Checkout from "components/Checkout";
import { useVenue } from "context/VenueContext";
import { useOrder } from "context/Order";

export default function CheckoutPage() {
  const history = useHistory();
  const venue = useVenue();
  const { order } = useOrder();

  const basketCount = order.items.length;

  useEffect(() => {
    if (!basketCount) {
      history.push(`/venues/${venue.id}`, { back: true });
    }
  }, [basketCount]);

  // Empty Basket, return to menu
  if (!basketCount) return null;

  return (
    <Page>
      <Checkout />
    </Page>
  );
}
