import { useEffect, useState } from "react";
import find from "lodash/find";
import { toast } from "react-toastify";
import { useVenue } from "context/VenueContext";
import { useDrawer } from "context/DrawerContext";

const useProducts = (props) => {
  let { products, categories, subcategories } = useVenue();
  const { openDrawer, closeDrawer } = useDrawer();

  const [active, setActive] = useState(null);

  const category = find(categories, ["id", props.category]);
  const subcategory = find(subcategories, ["id", props.subcategory]);

  useEffect(() => {
    // Check active product is still available
    if (active) {
      const activeProduct = find(products, ["id", active]);

      if (!activeProduct) {
        closeOverlay();
        toast("That product is no longer available.");
      }
    }
  }, [products.length]);

  const openOverlay = (id) => {
    setActive(id);
    openDrawer();
  };

  const closeOverlay = () => {
    closeDrawer();
    setActive(null);
  };

  // Filter products collection to currently displayed subcategory
  products = products.filter((p) => p.category.id === props.subcategory);

  const product = active ? find(products, ["id", active]) : null;

  return [
    products,
    product,
    category,
    subcategory,
    { openOverlay, closeOverlay },
  ];
};

export default useProducts;
