import React from "react";

const Loader = () => {
  return (
    <div className="absolute inset-0 m-auto flex items-center justify-center w-40 h-5/6 max-h-40">
      <div className="animate-scale absolute z-0 h-full w-full p-4 rounded-lg bg-purple-600 bg-gradient-to-r from-indigo-700 to-purple-600"></div>

      <img
        className="relative z-10 h-3/4 m-auto"
        src="/logo.svg"
        alt="Table Service App"
      />
    </div>
  );
};

export default React.memo(Loader);
