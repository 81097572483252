import { blue, red, green } from "tailwindcss/colors";

export default {
  base: {
    default: {
      fontFamily: "Gilroy, sans-serif",
      boxSizing: "border-box",
      padding: ".5rem .75rem",
      boxShadow: "none",
      fontSize: "1rem",
      borderRadius: ".5rem",
      lineHeight: "1.5",
      backgroundColor: "#fff",
      appearance: "none",
    },
    focus: {
      borderColor: blue[500],
      outline: "none",
    },
    error: {
      borderColor: red[500],
    },
    valid: {
      borderColor: green[500],
    },
    label: {
      display: "none",
    },
  },
  expiryDate: {
    container: {
      width: "48%",
      float: "left",
    },
  },
  cv2: {
    container: {
      width: "48%",
      marginLeft: "4%",
      float: "left",
    },
  },
};
