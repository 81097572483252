import { useEffect } from "react";
import PaymentProvider from "context/PaymentContext";
import { useModal } from "context/ModalContext";
import { useVenue } from "context/VenueContext";
import Modal from "components/Modal";
import Stripe from "components/checkouts/Stripe";
import StripeConnect from "components/checkouts/StripeConnect";
import Paymentsense from "components/checkouts/Paymentsense";
import Cash from "components/checkouts/Cash";
import { getPaymentMethods } from "lib/helper";

const OrderPaymentModal = (props) => {
  const venue = useVenue();
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    openModal();
  }, []);

  const handleClose = () => {
    closeModal();
    props.onClose();
  };

  const methods = getPaymentMethods(venue);

  return (
    <PaymentProvider onSubmit={props.onSubmit}>
      <Modal open onClose={handleClose}>
        <div className="relative p-4 text-center overflow-hidden">
          <h2 className="mb-2 text-2xl font-bold">Order Payment</h2>

          <div className="block w-full max-w-lg mx-auto">
            <div className="space-y-4">
              {methods.map((key, index) => {
                return (
                  <div key={key}>
                    {index !== 0 && <OR />}

                    {key === "stripe" && <Stripe />}
                    {key === "stripeConnect" && <StripeConnect />}
                    {key === "paymentsense" && <Paymentsense />}
                    {key === "cash" && <Cash />}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Modal>
    </PaymentProvider>
  );
};

export default OrderPaymentModal;

const OR = () => (
  <div className="relative text-center my-4">
    <span className="absolute top-1/2 -translate-y-1/2 left-0 border-b w-full border-gray-200"></span>
    <span className="relative z-10 px-2 bg-white">OR</span>
  </div>
);
