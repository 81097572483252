import clsx from "clsx";
import { useTheme } from "context/ThemeContext";
import { useVenue } from "context/VenueContext";

const SubNavigation = (props) => {
  const theme = useTheme();
  let { subcategories } = useVenue();

  subcategories = subcategories.filter((s) => s.parent.id === props.category);

  return (
    <ul className="mb-4 flex space-x-4 py-1 w-full overflow-x-auto no-scrollbar">
      {subcategories.map((subcategory) => {
        const isActive = props.subcategory === subcategory.id;

        return (
          <li key={subcategory.id}>
            <button
              onClick={() => props.onSelect(subcategory.id)}
              className={clsx(
                "inline-block whitespace-nowrap font-bold text-base leading-7 border-b-3 transition focus:outline-none",
                isActive ? "border-purple-600" : "border-transparent"
              )}
              style={{ borderColor: isActive ? theme.primary : "transparent" }}
            >
              {subcategory.name}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default SubNavigation;
