import { useTheme } from "context/ThemeContext";
import { formatCurrency } from "lib/helper";

const Product = (props) => {
  const theme = useTheme();

  const price = props.price;

  return (
    <button
      type="button"
      onClick={props.onOpen}
      className="block w-full p-2 text-left rounded-xl border-3 border-purple-600 focus:outline-none"
      style={{ borderColor: theme.primary }}
    >
      <div className="flex items-center">
        <div className="flex-1 pr-1">
          <h2 className="font-bold text-lg leading-snug">{props.name}</h2>
          <p className="leading-snug">{props.description}</p>
        </div>
        <div className="flex-initial">
          <span className="font-bold text-xl leading-none">
            {formatCurrency(price)}
          </span>
        </div>
      </div>
    </button>
  );
};

export default Product;
