import { useEffect, useState } from "react";
import { compact, isEmpty } from "lodash";
import clsx from "clsx";
import { useDebouncedCallback } from "use-debounce";
import { useTheme } from "context/ThemeContext";
import { useOrder } from "context/Order";
import { functions } from "lib/firebase";
import Drawer from "components/Drawer";
import { useDrawer } from "context/DrawerContext";
import { ReactComponent as Loader } from "./loader.svg";

const addressLookup = functions.httpsCallable("addressLookup");
const addressSelect = functions.httpsCallable("addressSelect");

export default function OrderAddress(props) {
  const theme = useTheme();
  const { order, setAddress } = useOrder();

  if (order.takeaway !== "delivery") return null;

  return (
    <div className="border-t-3 border-gray-100 py-2">
      <h4 className="block mb-1 text-lg font-bold">Address:</h4>

      {props.error && <div className="mb-2 text-red-500">{props.error}</div>}

      {order?.address ? (
        <>
          <address className="block not-italic mb-2">
            {formatAddress(order?.address)}
          </address>

          <button
            type="button"
            onClick={() => setAddress(null)}
            className="block py-2 px-4 rounded-lg font-bold text-sm uppercase text-white bg-purple-600 focus:outline-none"
            style={{
              color: theme.accent,
              backgroundColor: theme.primary,
            }}
          >
            Change address
          </button>
        </>
      ) : (
        <>
          <AddressLookup onSubmit={(address) => setAddress(address)} />

          <AddressManual onSubmit={(address) => setAddress(address)} />
        </>
      )}
    </div>
  );
}

function AddressLookup({ onSubmit }) {
  const [term, setTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState(null);

  const fetchSuggestions = useDebouncedCallback(async (term) => {
    setLoading(true);

    const { data } = await addressLookup({ term });
    setSuggestions(data?.suggestions || null);

    setLoading(false);
  }, 200);

  useEffect(() => {
    if (term.length < 3) {
      setSuggestions(null);
    } else {
      fetchSuggestions(term);
    }
  }, [term]);

  const handleAddress = async (id) => {
    setLoading(true);

    const { data } = await addressSelect({ id });
    onSubmit(data);
  };

  return (
    <div className="max-w-lg relative mb-4">
      <div className="relative">
        <input
          className="block w-full p-2 rounded appearance-none focus:outline-none border-2 border-purple-600"
          type="text"
          name="lookup"
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          autoComplete="off"
          autoComplete="chrome-off"
          placeholder="Enter your postcode"
        />

        {loading && (
          <Loader className="absolute top-3 right-3 animate-spin text-purple-600 h-5 w-5" />
        )}
      </div>

      {suggestions && (
        <ul
          className="relative w-full text-sm border border-gray-300 bg-white rounded-lg max-h-48 overflow-y-auto"
          aria-expanded="true"
        >
          {!suggestions.length && (
            <li className="TODO">
              <em>No addresses found</em>
            </li>
          )}

          {suggestions.map((suggestion) => (
            <li key={suggestion.id} className="border-b border-gray-300">
              <button
                className="block w-full p-2 hover:bg-gray-100 appearance-none text-left"
                type="button"
                onClick={() => handleAddress(suggestion.id)}
              >
                {suggestion.address}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

const defaultState = {
  line_1: "",
  line_2: "",
  town_or_city: "",
  county: "",
  postcode: "",
};

function AddressManual({ onSubmit }) {
  const theme = useTheme();

  const { open, openDrawer, closeDrawer } = useDrawer();

  const [errors, setErrors] = useState([]);
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    if (open) {
      setErrors([]);
      setState(defaultState);
    }
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation
    const required = ["line_1", "town_or_city", "postcode"];
    const formErrors = required.filter((key) => isEmpty(state[key]));

    if (formErrors.length > 0) {
      setErrors(formErrors);
    } else {
      onSubmit(state);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <>
      <button
        type="button"
        onClick={openDrawer}
        className="block font-bold text-sm uppercase text-purple-600 hover:underline focus:outline-none"
        style={{
          color: theme.primary,
        }}
      >
        Enter Manually
      </button>

      <Drawer open={open} onClose={closeDrawer}>
        <form onSubmit={handleSubmit} className="">
          <div className="mb-2">
            <label className="block font-bold" htmlFor="line_1">
              Address 1
            </label>
            <input
              className={clsx(
                "block w-full p-2 rounded appearance-none focus:outline-none border-2",
                errors.includes("line_1")
                  ? "border-red-500"
                  : "border-purple-600"
              )}
              type="text"
              name="line_1"
              value={state.line_1}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-2">
            <label className="block font-bold" htmlFor="line_2">
              Address 2
            </label>
            <input
              className="block w-full p-2 rounded appearance-none focus:outline-none border-2 border-purple-600"
              type="text"
              name="line_2"
              value={state.line_2}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-2">
            <label className="block font-bold" htmlFor="town_or_city">
              Town
            </label>
            <input
              className={clsx(
                "block w-full p-2 rounded appearance-none focus:outline-none border-2",
                errors.includes("line_1")
                  ? "border-red-500"
                  : "border-purple-600"
              )}
              type="text"
              name="town_or_city"
              value={state.town_or_city}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-2">
            <label className="block font-bold" htmlFor="county">
              County
            </label>
            <input
              className="block w-full p-2 rounded appearance-none focus:outline-none border-2 border-purple-600"
              type="text"
              name="county"
              value={state.county}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label className="block font-bold" htmlFor="postcode">
              Postcode
            </label>
            <input
              className={clsx(
                "block w-full p-2 rounded appearance-none focus:outline-none border-2",
                errors.includes("line_1")
                  ? "border-red-500"
                  : "border-purple-600"
              )}
              type="text"
              name="postcode"
              value={state.postcode}
              onChange={handleInputChange}
            />
          </div>

          <button
            type="submit"
            className="block w-full p-3 rounded-lg font-bold text-base uppercase text-white bg-purple-600 focus:outline-none"
            style={{
              color: theme.accent,
              backgroundColor: theme.primary,
            }}
          >
            Save address
          </button>
        </form>
      </Drawer>
    </>
  );
}

function formatAddress(address) {
  let parts = [
    address?.line_1,
    address?.line_2,
    address?.town_or_city,
    address?.county,
    address?.postcode,
  ];

  parts = compact(parts);

  return parts.join(", ");
}
