import { useOrder } from "context/Order";
import { useTheme } from "context/ThemeContext";

export default function CustomerName(props) {
  const theme = useTheme();

  const { order, setCustomerName } = useOrder();

  const handleInputChange = (e) => setCustomerName(e.target.value);

  if (order.takeaway !== "collection") return null;

  return (
    <div className="border-t-3 border-gray-100 py-2">
      <label htmlFor="notes" className="block mb-1 text-lg font-bold">
        Your Name:
      </label>

      {props.error && <div className="mb-2 text-red-500">{props.error}</div>}

      <input
        type="text"
        id="customerName"
        value={order.customerName}
        onChange={handleInputChange}
        className="block mb-2 w-full p-2 rounded appearance-none focus:outline-none border-2 border-purple-600"
        style={{ borderColor: theme.primary }}
        placeholder="Enter your name"
        autoComplete="name"
      />
    </div>
  );
}
