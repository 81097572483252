import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import get from "lodash/get";
import { useVenue } from "context/VenueContext";
import StripeCheckout from "./StripeCheckout";

let promise;

const getStripe = (key) => {
  if (!promise) promise = loadStripe(key);

  return promise;
};

export default function Stripe() {
  const venue = useVenue();

  const key = get(venue, "payments.stripe.publishable");

  if (!key) return null;

  return (
    <Elements stripe={getStripe(key)}>
      <StripeCheckout />
    </Elements>
  );
}
