import { Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import VenueRoute from "components/VenueRoute";

import IndexPage from "pages";
import VenuePage from "pages/venue";
import CheckoutPage from "pages/checkout";

const AnimatedSwitch = ({ children }) => {
  return (
    <Route
      render={({ location }) => {
        const shallowPathname = location.pathname.split("/")[1];

        return (
          <AnimatePresence initial={false} custom={location.state}>
            <Switch location={location} key={shallowPathname}>
              {children}
            </Switch>
          </AnimatePresence>
        );
      }}
    />
  );
};

const Routes = () => {
  return (
    <AnimatedSwitch>
      <VenueRoute path="/venues/:id" component={VenuePage} />
      <VenueRoute path="/checkout" component={CheckoutPage} />
      <Route path="*" component={IndexPage} />
    </AnimatedSwitch>
  );
};

export default Routes;
