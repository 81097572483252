const reducer = (state, action) => {
  switch (action.type) {
    case "SELECT_CATEGORY":
      const subcategory = getFirstSubcategory(
        state.subcategories,
        action.category
      );

      return {
        ...state,
        category: action.category,
        subcategory: subcategory.id,
      };

    case "SELECT_SUBCATEGORY":
      return { ...state, subcategory: action.subcategory };

    default:
      throw new Error();
  }
};

export default reducer;

export const initialState = (categories, subcategories) => {
  const category = getFirstCategory(categories);
  const subcategory = getFirstSubcategory(subcategories, category?.id);

  return {
    categories,
    subcategories,
    category: category?.id,
    subcategory: subcategory?.id,
  };
};

const getFirstCategory = (categories) => categories.find(Boolean);

const getFirstSubcategory = (subcategories, id) => {
  return subcategories.find((s) => s.parent.id === id);
};
